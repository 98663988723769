import React, { useState, FC } from 'react';
import styled from 'styled-components';
import { IWidgetData, IStrongs, TWidgetTypes } from './Widget';
import TabPanel from 'src/components/TabPanel';

import { CircleMode, isSynastry } from 'src/libs';

import { getTabs } from './utils';

import { useTranslation } from 'src/i18n/useTranslation';
import { getModeMetaData, isCompatibility } from '../utils';
import { TWidgetCircleMode } from './AspectsTable/ModeSelectorTabs';

export interface IWidgetProps {
  data: IWidgetData,
  tab?: string,
  onChanged?(key: string, value: any): void,
  strongs?: IStrongs
  widgetMode: TWidgetCircleMode
}

export default function TabPanelWrapper(props: { 
  widgetType: TWidgetTypes; 
  data: IWidgetData; 
  Widget: FC<IWidgetProps>; onChanged?: (key: string, value: any) => void 
}) {
  const { data, Widget } = props;
  const tabs = getTabs(data.modes);
  //const tabs = data.modes;

  const [selected, setSelected] = useState<string>(props.data.widgetMode);
  const [prevSelected, setPrevSelected] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  React.useEffect(() => {
    const modes = props.data.modes;
    setSelected(props.data.widgetMode);

    if (isSynastry(selected as CircleMode)) {
      if (isCompatibility(selected as CircleMode)) {
        if (modes.length > 1) {
          const valueForPrevSelected = modes.find(
            (mode) => !isCompatibility(mode)
          );
          setPrevSelected(valueForPrevSelected);
        }
      } else {
        setPrevSelected(selected);
      }
    }
  }, [props.data.modes, props.data.widgetMode])

  if (data.modes.some(isCompatibility)) {
    if (tabs?.length < 1) {
      return <Widget {...{...props, widgetMode: props.data.widgetMode } } />;
    }
    return (
      <TabPanel
        key={data.modes.join('-')}
        tabs={tabs.map(mode => ({
          title: t(getModeMetaData(mode, data.form).title ?? ''),
          id: mode,
          component: <Widget {...{...props, widgetMode: props.data.widgetMode }} tab={mode} />
        }))}
        selected={!isCompatibility(selected as CircleMode) ? selected : prevSelected}
      />
    );
  }
  return <Container>
    <ContainerMain>
      <main>
        <Widget {...{...props, widgetMode: props.data.widgetMode } } tab={data.widgetMode}/>
      </main>
    </ContainerMain>
  </Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > main {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    border-top: 1px solid var(--element-light);
  }
`;
