import React from 'react';
import styled, { css } from 'styled-components';

import { CircleMode, astroObjects, signs, AspectsPatterns, JonesPatterns, IAspectPattern } from 'src/libs';

import { ReactMemo } from 'src/hooks';

import { SynthesisSigns } from './SynthesisSigns';
import FixedStarsView from '../FixedStarsView';

import { default as TW } from 'src/ui/Wrappers/TooltipWrapper';
import Svg from 'src/ui/Svg';

import {InfoIcon1 } from 'src/assets/icons/notifications';
import store from 'src/store';
import { IWidgetProps } from '../TabPanelWrapper';
import { useTranslation } from 'src/i18n/useTranslation';
import { isPartner, isPrognostics, isSynNatal } from '../../utils';
import Theme from'src/theme';

import { extractMode } from '../AspectsTable/utils';

export default ReactMemo(function Analyze(props: IWidgetProps) {
  
  const _subMode = extractMode(props.widgetMode, '-'); // вытаскиваем субрежим из режима виджета // directions-stars
  const isPrognosticsSubMode = isPrognostics(_subMode as CircleMode) && _subMode !== props.widgetMode;
  
  let _map = props.tab && isPartner(props.tab as CircleMode)
    ? props.data.maps.find(map => map.mode === props.tab) || props.data.partnersMaps?.[props.tab]
    : (isPrognosticsSubMode
        ? props.data.maps.find(map => map.mode === _subMode)
        : props.data.natalMap // если нет ни каких прогностических субмодов, то берем natalMap
      )

  // This cannot happen because this widget should not be visible without a natal map
  if (!_map) { return null }

  // console.log(`*** widget analyse map - widgetMode: ${props.widgetMode}, extractedMode: ${_subMode}, mapMode: `,_map.mode);

  const usedObjects = _map.objects.filter((item, i) => i < 10);

  const aspects = _map.aspects.filter(item => item.obj1 < 10 && item.obj2 < 10);

  const aspectsPatterns_ = new AspectsPatterns(aspects);
  let aspectsPatterns = aspectsPatterns_.findPatterns() as IAspectPattern[];

  // Добавляем нумерацию
  aspectsPatterns.forEach((item, i) => item.id = i + 1);
  aspectsPatterns = aspectsPatterns_.mergePatterns(aspectsPatterns, aspects);

  // Если включена настройка объединяем объекты по соединениям, подробнее см в 5741 п.3
  if (store.activeAstroProfile && store.activeAstroProfile.planetConnectionConfig) {
    aspectsPatterns = aspectsPatterns_.mergeConjunctionObjectsToPatterns(aspectsPatterns, aspects);
  }

  const jonesPatterns = new JonesPatterns(usedObjects, _map.houses, _map.aspects, aspectsPatterns);
  const jonesPattern = jonesPatterns.getPattern();

  const synthesisSigns_ = new SynthesisSigns(usedObjects, _map.houses);
  const synthesisCrosses = synthesisSigns_.getCrossesWithObjects();

  const synthesisSigns = synthesisSigns_.getSigns();
  const themeZodiacsCurrent = store.settings.personalization.current;
  const signColor = themeZodiacsCurrent.circle.colorThemes.zodiacs.active === 0 ? 'currentColor' : 'black ';   


  const higlightJonesPattern = () => {
    props.onChanged && props.onChanged(
      'highlights',
      usedObjects.map((item, index) => ({
        id: index,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightJonesOption = (option: any) => {
    props.onChanged && props.onChanged(
      'highlights',
      option.objects.map((item: any) => ({
        id: item.id,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightAspectsPattern = (pattern: any) => {
    const highlights = {
      items: [
        ...pattern.points.map((item: number) => ({
          id: item,
          type: 'object',
          map: 'natal'
        })),
        ...pattern.aspects.map((item: any) => ({
          id: item.id,
          type: 'aspect',
          map: 'natal'
        }))
      ],
      aspects: _map?.mode === 'natal' && !isSynNatal && !isPrognostics ? aspects : null
    };
    props.onChanged && props.onChanged('highlights', highlights);
  };

  const highlightElement = (element: any) => {
    props.onChanged && props.onChanged(
      'highlights',
      element.objects.map((item: any) => ({
        id: item.id,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightSign = (sign: number) => {
    props.onChanged && props.onChanged(
      'highlights',
      [
        {
          id: sign,
          type: 'sign',
          map: 'natal'
        }
      ]
    );
  };

  const removeHighlight = () => {
    props.onChanged && props.onChanged(
      'highlights',
      []
    );
  };

  const { t } = useTranslation();

  const getSortPoints = (points: number[], mergedObjects: number[][]) => {
    const styledPoints: JSX.Element[] = [];
    
    mergedObjects.forEach(group => {
      styledPoints.push(
          <SVGGroup key={group.toString()}>
              {group.map(point => (
                  <Svg key={point} className={astroObjects[point].en} path={astroObjects[point].icon}/>
              ))}
          </SVGGroup>
      );
  });
    points.filter(point => !mergedObjects.flat().includes(point)).forEach(point => {
      styledPoints.push(
        <Svg key={point} className={astroObjects[point].en} path={astroObjects[point].icon} />
      );
    });
  
    return styledPoints;
  };

  return (
    <Container>
      {!props.tab && <Hr />}

      {!isPrognosticsSubMode && <>
        {jonesPattern && <Group>
          <header>{t("chronos.app.instruments.widgets.analyze.figureJones")} <TW text={t("chronos.app.instruments.widgets.analyze.jones.description")}><InfoIcon1 /></TW></header>
          <Buttons>
            <Button active onMouseOver={higlightJonesPattern} onMouseOut={removeHighlight}>{t(jonesPattern.name)}</Button>
            {jonesPattern.options.map((item: any, i: number) =>
              <Button key={i} onMouseOver={highlightJonesOption.bind(null, item)} onMouseOut={removeHighlight}>
                {t(item.name)}
                {item.objects.map((object: any, iconI: number) => <Svg key={iconI} className={astroObjects[object.id].en} path={astroObjects[object.id].icon} />)}
              </Button>
            )}
          </Buttons>
        </Group>}
  
        {aspectsPatterns.length > 0 && <Group>
          <header>{t("base.configurations")} <TW text={t("chronos.app.instruments.widgets.analyze.jones.description.aspectsConfigurations")}><InfoIcon1 /></TW></header>
          <Buttons>
          {aspectsPatterns.map((item: any, i: number) => (
          <Button key={i} onMouseOver={highlightAspectsPattern.bind(null, item)} onMouseOut={removeHighlight}>
            {t(item.name)}
            {getSortPoints(item.points, item.mergedObjects || [])}
          </Button>
        ))}
          </Buttons>
        </Group>}
  
        <Group>
          <header>{t("chronos.app.instruments.widgets.analyze.signSintez")} <TW text={t("chronos.app.instruments.widgets.analyze.syntesis.description")}><InfoIcon1 /></TW></header>
          <Crosses>
            {synthesisCrosses.map((item: any, i: number) =>
              <Cross key={i}>
                <header>{t(item.title)} <span>{item.objectsCount}</span></header>
                <Elements>
                  {item.elements.map((element: any, i: number) =>
                    <Element key={i} color={element.color} onMouseOver={highlightElement.bind(null, element)} onMouseOut={removeHighlight}>
                      {element.objects.map((object: any, iconI: number) => <Svg key={iconI} className={astroObjects[object.id].en} fill={signColor} path={astroObjects[object.id].icon} />)}
                    </Element>
                  )}
                </Elements>
              </Cross>
            )}
          </Crosses>
        </Group>
  
        <Signs>
          <header>{t("chronos.app.instruments.widgets.analyze.sintez")}</header>
  
          {synthesisSigns.map((signIndex: number) =>
            <Sign key={signIndex} onMouseOver={highlightSign.bind(null, signIndex)} onMouseOut={removeHighlight} style={{
              color: `var(--circle-zodiacs-elements-${signs[signIndex].element})`
            }}>
              <Svg className={signs[signIndex].en} path={signs[signIndex].icon} viewBox="-64 -64 128 128" /> {t(signs[signIndex].ru)}
            </Sign>
          )}
        </Signs>
      </>} 

      { 
        <StyledFixedStarsView
          title={t("chronos.app.instruments.widgets.horar.analysis.stars")}
          map={_map}
          // @ts-ignore
          mode={props.widgetMode as CircleMode}
          // @ts-ignore
          onChanged={props.onChanged}
        />
      }
    </Container>
  );
});

const Container = styled.div`
  //max-width: 30rem;
`;

const Hr = styled.div`
  height: 0px;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--element-neutral);
`;

const Group = styled.div`
  margin-bottom: 1.125rem;
  font-size: 1rem;
  color: var(--text-primary);

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;

    & svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      color: var(--icon-third);
    }
  }
`;
const SVGGroup = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
  border-radius: 6px;
  background-color: ${() => Theme.current === 'dark' ? '#717171' : '#D2D2D2'};

  & > svg {
    width: 1.4rem; 
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const Button = styled('button') <{ active?: boolean }>`
  min-height: 2.1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.250rem;

  padding: 0.3rem 0.75rem;
  color: var(--text-primary);
  border-radius: 5px;
  background: var(--element-neutral);
  border: 1px solid var(--input-border);
  cursor: pointer;
  outline: none;

  &:hover {
    background: var(--element-neutral-hover);
  }

  ${props => props.active && css`
    color: white;
    background: var(--accent-blue) !important;
  `}

  font-size: 0.875rem;

& > svg {
  width: 1.4rem;

}

& > span {
  color: var(--text-secondary);
}
`;

const Crosses = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
`;

const Cross = styled.div`
  margin-left: 0.5rem;

  &:first-child {
    margin-left: 0;
  }

  & header {
    font-size: 0.875rem;
    margin-bottom: 0.8rem;

    & span {
      color: var(--text-secondary);
      margin-left: 0.25rem;
    }
  }
`;

const Elements = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Element = styled('div') <{ color: string }>`
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem;
  border-radius: 34px;
  background: ${props => props.color};
  cursor: pointer;
  margin-left: 0.5rem;
  color: white;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const Signs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 0.875rem;
  color: var(--text-secondary);

  & header {
    color: var(--text-primary);
  }
`;

const Sign = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    width: 1rem;
    margin-right: 0.4rem;
  }
`;

const StyledFixedStarsView = styled(FixedStarsView)`
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;

  .section-title {
    color: var(--text-primary);

    &.empty {
      font-size: 0.8em;
    }
  }

`;