import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { observer } from 'mobx-react';
import { cloneDeep, set } from 'lodash';
import dayjs from 'dayjs';
import { RelocationsMode, getGMT, Gender, IFixedGmt, IPlace, IPlaceEx, degToString, Input, Button, CommonPopup, show, CircleLoader, Switcher, Tooltip, EditFormType, ISynastryPartnerData } from 'src/libs';
import { HousesSystem, IFormData, IHorarData } from 'src/libs';
import api from 'src/api';
import Confirmation from 'src/components/Confirmation';
import { switcherHook } from 'src/hooks';
import store from 'src/store';
import StarData from 'src/pages/Settings/sections/stars.json';
import { isCustomPlace } from 'src/utils';


import { PersonIcon, PlusIcon, CopyIcon, ClipboardIcon } from 'src/assets/icons/system';

import StarCatalog from 'src/pages/Settings/StarCatalog';
import { StarIcon, ChevronRightIcon } from 'src/assets/icons/system/24';

import CloseButton from 'src/ui/CloseButton';
import MainGroup, { infos, Label } from './MainGroup';
import { checkGenChanged, getGMTToString, getIntervalWithThisGmt, getFixedGmt } from 'src/utils';
import { useTranslation } from 'src/i18n/useTranslation';

import { Section } from './Section';
import { HousesSystemSelect } from './HousesSystemSelect';
import { ICard } from 'src/store/Dashboard';
import RelacationsMainGroup from './RelacationsMainGroup';
import { formGenLS } from 'src/helpers/localStorage';

import { MAX_PARTNERS_AMOUNT, ExtEditFormType, IFormError, IPartnerDataWithAdditionalParams } from './utils';


export default observer(function EditForm(props: {
  type: ExtEditFormType;
  onClose(): void;
  form: IFormData;
  onSubmit(form: IFormData): void;
  currentMode?: string;
}) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const [form, setForm] = useState(cloneDeep(props.form));
  const [activeRelocation, setActiveRelocation] = useState((props.currentMode ?? 'relocation_natal') as RelocationsMode);

  const { natal, horar } = form;
  const data = (props.type === 'relocation'
    ? form.relocation?.[activeRelocation]
    : Boolean(props.type === 'syn_prognostics' && form.syn_prognostics == null)
      ? { ...natal, dt: dayjs().local().add(dayjs().utcOffset(), 'minute').toISOString() }
      : form[props.type])
    ?? natal;

  const info = infos[props.type];
  const { astro: astroStore } = store;
  const { cardSettingsForms } = store.settings.profile.ui.instruments;
  const sections = (cardSettingsForms as any)[props.type] || cardSettingsForms.common;

  const [place, setPlace] = useState<IPlace>(data.place);
  const [dateTime, setDateTime] = useState(data.dt);
  
  const hasGen = checkGenChanged(form.gen, dateTime, form.natal.dt, place!, form.natal.place);
  const isSupportClipboardApi = Boolean(navigator.clipboard?.readText);

  
  const [fixedGmt, setFixedGmt] = useState<{ [key: string]: IFixedGmt }>({ ...(store.settings.user.profile?.fixedGmt ?? {}) });
  const [name, setName] = useState(form.name);
  const [housesSystem, setHousesSystem] = useState(props.type === 'horar' ? form.housesSystem.horar : HousesSystem.Placidus);
  const [cosmogram, setCosmogram] = useState(form.cosmogram);
  const [gender, setGender] = useState((data as ISynastryPartnerData)?.gender);
  const [question, setQuestion] = useState(horar?.question || '');
  const [showAspects, setShowAspects] = useState(store.settings.profile.ui.instruments.cardSettingsForms.horar.showAspects);
  const [showObjectCatalog, setShowObjectCatalog] = useState<boolean>(false);
  const [showHigherPlanets, setShowHigherPlanets] = useState(store.activeAstroProfile?.showHigherPlanets);
  const [fixedStars, setFixedStars] = useState<{[key: string]: any }>({ ...store.activeAstroProfile?.fixedStars });
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [invalidDateTime, setInvalidDateTime] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [pasteSuccess, setPasteSuccess] = useState(false);
  const [calcRangeForGmtPromise, setCalcRangeForGmtPromise] = useState<Promise<any> | null>(null);
  const [autoGmt, setAutoGmt] = useState<boolean | undefined>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [useNatalPlace, setUseNatalPlace] = useState(form.useNatalPlace ?? astroStore.profiles.find(p => p.mutable)?.useNatalPlace);
  const [partnersData, setPartnersData] = useState<IPartnerDataWithAdditionalParams[]>(
    form.partners?.length
      ? form.partners.map(partner => ({...partner, opened: false}))
      : form.synastry
          ? [{...form.synastry, opened: false}]
          : []
  );

  const setGMT = (v: number) => _setGMT(v);

	const switchPartnerOpened = (currentIndex: number) => {
		setPartnersData(partnersData => partnersData.map((item, index) => index === currentIndex ? {...item, opened: !item.opened} : item ))
	}

  const basicIsOpened = switcherHook(props.type === 'relocation' ? false : (sections as any)?.basic);
  const additionalIsOpened = switcherHook((sections as any)?.additional ?? true);
  const showConfirmation = switcherHook(false);

  

  // 
  const userFixedGmt = useMemo((): number => {
    const { gmt: userFixedGmt } = getFixedGmt({
      dt: dateTime,
      lat: place.lat,
      lon: place.lon,
      data: fixedGmt
    });
    return userFixedGmt
  }, [fixedGmt, dateTime, place]);

  const partnersFixedGmt = useMemo((): number[] => {
    const result: number[] = [];

    partnersData?.forEach((partner: ISynastryPartnerData, idx: number) => {
      const { gmt: partnerFixedGmt } = getFixedGmt({
        dt: partner.dt,
        lat: partner.place.lat,
        lon: partner.place.lon,
        data: fixedGmt
      });
      result.push(partnerFixedGmt)
    });

    return result;

  }, [fixedGmt, partnersData]);

  const [gmt, _setGMT] = useState<number>(userFixedGmt ?? data.gmt);

  const formOptions = useMemo(() => {
    const opts = store.dashboard.forms
      ?.filter((f: ICard) => (f.id !== form.id) && !f.isTemporary)
      .map((f: ICard) => ({
        label: f.name,
        value: +f.id,
        icon: <PersonIcon fill={f.color} />
      }))
    .sort((f1: {label: string; value: number}, f2: {label: string; value: number}) => {
      if(f1.label === f2.label) {
        return 0;
      }
      return f1.label > f2.label ? 1 : -1;
    }) || [];
    return [{label: t('chronos.app.components.editForm.newMap'), value: 0, icon: <PlusIcon />}].concat(opts);
  }, [store.dashboard.forms, form])


  useEffect(() => {
    // FIXME: hack
    if (data && data.place?.lon && data.place?.lat) {
      setGMT(userFixedGmt ?? data.gmt);
    }

    const onEscKey = (e: any) => {
      if (e.keyCode === 27) {
        props.onClose();
      }
    }

    window.addEventListener('keydown', onEscKey);

    return () => window.removeEventListener('keydown', onEscKey);
  }, []);

  useEffect(() => {
    if (props.type === 'synastry' && partnersData.length === 0) {
      addNewPartner()
    }
  }, [props.type, partnersData])


  const changeShowAspects = useCallback(() => {
    setShowAspects(showAspects => !showAspects);
  }, [showAspects]);

  const onChangeUseNatalPlace = (val: boolean) => {
    setUseNatalPlace(val);

    const _form = cloneDeep(form);
    _form.useNatalPlace = val;
    setForm(_form);
    if (val) {
      setPlace({...form.natal.place});
    }
  }

  const copyNatalPlace = (form: IFormData, place: IPlace) => {
    if (!form.prognostics) return getGMT(form.natal.dt, place?.lat as number, place?.lon as number)
    form.prognostics.place = { ...place };
    return form.prognostics.gmt = getGMT(form.prognostics.dt, place?.lat as number, place?.lon as number)
  };

  const onPlaceChange = (place: any, index?: number) => {
    handlePlace(place, index);

    if ([place.lat, place.lon].some((v) => typeof v !== 'number')) return;

    if (props.type === 'relocation') {
      const _form = cloneDeep(form);
      const relocation = _form.relocation ?? (_form.relocation = {});
      if (relocation[activeRelocation]) {
        relocation[activeRelocation]!.place = place
        _form.relocation = relocation
        setForm(_form)
      }


      if (dateTime) {
        let relocationGmt = getGMT(form.natal.dt, place.lat, place.lon);
        const relocationDt = dayjs(form.natal.dt).add(relocationGmt - form.natal.gmt!, 'hours').toISOString();
        setDateTime(relocationDt);
      }
    } else if (props.type !== 'natal' && useNatalPlace) {
      copyNatalPlace(form, place);
    }
  };

  const handlePlace = (val: IPlace, index?: number) => {
    if (typeof index === 'number') {
      setPartnerVal({
        place: val,
        id: undefined,
        gmt: partnersData[index].dt
          ? getGMT(partnersData[index].dt, val.lat, val.lon)
          : partnersData[index].gmt
      }, index);
    } else {
      setPlace(val);
      if (dateTime) {
        const newGmt = getGMT(dateTime, val.lat || 0, val.lon || 0);
        setGMT(newGmt);
      }
    }
  }

  const onDateTimeChange = (dt: string) => {
    setDateTime(dt);

    setInvalidDateTime(false);

    if (place?.lat && place?.lon) {
      // ищем gmt, в хеше fixedGmt
      const _gmt = getGMT(dt, place.lat, place.lon)
      const { gmt: _fixedGmt } = getFixedGmt({ dt, lat: place.lat, lon: place.lon, data: fixedGmt });
      setGMT(_fixedGmt ?? _gmt);
    }

    if (
      props.type === 'natal' &&
      props.form.relocation &&
      Object.keys(props.form.relocation).includes('relocation1')
    ) {
      const _form = cloneDeep(form);

      Object.values(_form.relocation ?? {}).forEach((r: ISynastryPartnerData) => {
        r.dt = dayjs(dt).add(r.gmt! - gmt, 'hours').toISOString();
      });

      setForm(_form);
    }
  };

  const onChangeGMT = (val: number, auto: boolean, index?: number) => {
    const currentGmt = val;
    let calculatedGmt: number | null = null;
    const partner = typeof index === 'number' && (partnersData[index] as ISynastryPartnerData);
    const currentPlace = (partner ? partner.place : place) as IPlaceEx;
    const currentDateTime = partner ? partner.dt : dateTime;

    const forCalcLat = currentPlace?.lat || currentPlace?.originalLat;
    const forCalcLon = currentPlace?.lon || currentPlace?.originalLon;


    if (forCalcLat && forCalcLon && currentDateTime) {
      calculatedGmt = getGMT(currentDateTime, forCalcLat, forCalcLon);
    }

    if (auto) {
      //@ts-ignore
      calcRangeForGmtPromise?.cancel();
      // попадает ли текущая дата в какой-нибудь сохранненный gmt-интервал
      const { key: dtKey } = getFixedGmt({ dt: currentDateTime, lat: currentPlace?.lat, lon: currentPlace?.lon, data: fixedGmt });
      const { [dtKey]: deletedKey, ...newFixedGmt } = fixedGmt;
      setFixedGmt({ ...newFixedGmt });

      if (partner) {
        calculatedGmt && setPartnersGMT(calculatedGmt, index);
      } else {
        calculatedGmt && setGMT(calculatedGmt);
        (userFixedGmt && !isCustomPlace(currentPlace)) && setAutoGmt(true);
      }
      setDisableForm(false);
      return
    } 
    if (!isCustomPlace(currentPlace)) {
      
      setDisableForm(true);
      setShowLoader(true);
      const promiseFn = getIntervalWithThisGmt(currentDateTime, currentGmt, currentPlace!);
      setCalcRangeForGmtPromise(promiseFn);

      promiseFn.then(({ dateKey, start, end, gmt, place }) => {
        setFixedGmt({
          ...fixedGmt,
          [dateKey]: {
            range: [start, end],
            gmt: gmt,
            lat: place.lat,
            lon: place.lon
          }
        })

        setDisableForm(false);
        setShowLoader(false);
      })

      setAutoGmt(false);
    }

    if (partner) {
      setPartnersGMT(currentGmt, index);
    } else {
      setGMT(currentGmt);
    }
  };


  const sectionsChanged = (key: string, value: boolean) => {
    if (props.type === 'relocation') return;

    store.settings.profile.updateUi(ui => {
      (ui.instruments.cardSettingsForms as any)[props.type][key] = value;
    });
  };

  const checkHandler = useCallback(() => {
    const errors: IFormError[] = [];
    const prognosticsUseNatal = (props.type === 'prognostics') && useNatalPlace;
    // @ts-ignore
    const { name = '', originalName, lat, lon, originalLat, originalLon, showUserCoordinates } = place as IPlaceEx;

    if (props.type === 'synastry') {
      partnersData?.forEach((partnerData, index) => {
        const formName = t(`astro.partner${index + 1}`);
        if(!partnerData.name) {
          errors.push({
            node: 'synastry',
            text: `${formName}: ${t('chronos.app.components.editForm.noName')}`,
          });
        }

        if (partnerData.invalidDateTime) {
          errors.push({
            node: 'synastry',
            text: `${formName}: ${t('chronos.app.inputBirthDate')}`,
          });
        }

        if (invalidDateTime) {
          errors.push({
            node: 'natal',
            text: t("chronos.app.inputBirthDate")
          });
        }

        if ((partnerData?.place?.name.trim().length === 0)) {
          errors.push({
            node: 'synastry',
            text: `${formName}: ${t('chronos.app.noLocation')}`,
          });
        }

        if (!partnerData?.place?.lat || !partnerData?.place?.lon) {
          errors.push({
            node: 'synastry',
            text: `${formName}: ${t('chronos.app.noCoordinates')}`,
          });
        }
      });

      setErrors(errors);
      return !!errors.length
    
    } else {
      
      if ((name?.trim().length === 0) && !prognosticsUseNatal) {
        errors.push({
          node: 'natal',
          text: "chronos.app.noLocation"
        });
      }

      if (name && name !== originalName && (lat === originalLat && lon === originalLon)) {
        errors.push({
          node: 'natal',
          text: t("chronos.app.choosePlaceFromList")
        });
      }

      if ((('originalLat' in place && 'originalLon' in place) || showUserCoordinates) && (!lat || !lon) && !prognosticsUseNatal) {
        errors.push({
          node: 'natal',
          text: t("chronos.app.noCoordinates")
        });
      }

      if (props.type === 'horar' && question.trim().length === 0) {
        errors.push({
          node: 'natal',
          text: "chronos.app.questionInput"
        });
      }
  }


    setErrors(errors);
    return !!errors.length
  }, [place, question, invalidDateTime, useNatalPlace, partnersData]);


  const resetGen = (form: IFormData) => {
    form.gen = {
      devplan: false,
      autoprediction: false,
      rectification: false,
    };

    formGenLS.set(form.id, form.gen);
  };

  const updateAstroProfile = async (newValue: { [key: string]: any }, saveToBackend: boolean) => {
    let profile = cloneDeep(store.activeAstroProfile!);

    profile = {
      ...profile,
      ...newValue
    };

    if (saveToBackend) {
      profile.mutable = true
    }

    return astroStore.updateProfile(profile);
  }

  const onSubmit = (evt?: any) => {
    evt?.preventDefault();

    window.setTimeout(() => {
      if (checkHandler()) return;

      if (props.type === 'natal') {
        if (hasGen) {
          showConfirmation.on();
          return;
        }
      }

      onSubmitForm();
    }, 200);
  };

  const onSubmitForm = async () => {

    showConfirmation.off();

    const _form = cloneDeep(form);

    _form.name = name;

    const node: ISynastryPartnerData = {
      dt: dateTime,
      gmt,
      place: place!
    };

    node.gender = gender;

    if (props.type === 'natal') {
      _form.cosmogram = cosmogram;

      if (hasGen) resetGen(_form)
    const _date = dayjs.utc(dateTime).format('DD.MM.YYYY');;
    const _time = dayjs.utc(dateTime).format('HH:mm:ss');

      //@ts-ignore
      _form['natal'].dateTime = {
      //@ts-ignore
        ..._form['natal'].dateTime,
        date: _date,
        time: _time        
      }
    }

    if (props.type === 'synastry') {
      _form.partners = partnersData.map(partner => ({
        ...partner,
        id: undefined,
        invalidDateTime: undefined,
      }))
    }

    if (props.type === 'horar') {
      (node as IHorarData).question = question;
      _form.housesSystem.horar = housesSystem;
    }

    if (props.type === 'prognostics' && useNatalPlace && _form.prognostics) {
      const natalPlace = _form['natal'].place;
      node.place = {...natalPlace};
      node.gmt = getGMT(_form.prognostics.dt, natalPlace?.lat as number, natalPlace?.lon as number);
    }

    if (props.type === 'syn_prognostics' && useNatalPlace && _form.syn_prognostics) {
      const natalPlace = _form['natal'].place;
      node.place = {...natalPlace};
      node.gmt = getGMT(_form.syn_prognostics.dt, natalPlace?.lat as number, natalPlace?.lon as number);
    }

    if (props.type !== 'relocation') {
      node.autoGmt = Boolean(autoGmt);
      (_form as any)[props.type] = {
        ...(_form as any)[props.type],
        ...node
      } as ISynastryPartnerData & IHorarData;
    }

    // уведомляем о смене autoGmt
    if ((autoGmt !== undefined) && props.type !== 'relocation') {
      let text, type;
      if (autoGmt === false) {
        text = 'Персональные настройки GMT включены';
        type = 'warning'
      } else if (autoGmt === true) {
        text = 'Персональные настройки GMT отключены';
        type = 'info'
      }

      show({
        // @ts-ignore
        type,
        closable: true,
        timeout: 5000,
        text
      });
    }

    await props.onSubmit(_form);

    store.settings.profile.updateUi(ui => {
      ui.instruments.cardSettingsForms.horar.showAspects = showAspects;
    });

    await store.settings.user.updateProfile({fixedGmt: { ...fixedGmt }}, Boolean('remote'))
    props.onClose();
  };

  const copyHandler = () => {
    if (checkHandler()) return;
    const g = gender === 'female' ? 'F' : (gender === 'male' ? 'M' : '');
    const dt = dayjs(dateTime).utc();

    const lat = degToString(place!.lat, { isInternational: true, showSeconds: true, isLatitude: true });
    const lon = degToString(place!.lon, { isInternational: true, showSeconds: true, isLatitude: false });

    const convertedGMT = getGMTToString(gmt);
    const shortResult = `${form.name};${dt.format('DD.MM.YYYY')};${dt.format('HH:mm:ss')};+${convertedGMT};${place!.name};${lat};${lon};${g};;`
    
    const result = {
      name: form.name,
      dt: dateTime,
      gmt: gmt,
      place: place,
      gender: gender
    }

    store.instruments.setFormData(result)

    navigator.clipboard?.writeText(shortResult).then(_ => {
      setCopySuccess(true);

      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    })
  };
  
  const pasteHandler = () => {
    const data = store.instruments.getLSFormData()

    if (data) {
      const { name, dt, gmt, place, gender } = data;
      setName(name);
      setDateTime(dt);
      onPlaceChange(place);
      setGMT(gmt);
      setGender(gender);
      setPasteSuccess(true);
    } else {
      throw Error('Ошибка получения данных')
    }
    setTimeout(() => {
      setPasteSuccess(false);
    }, 3000);
  };

  const copyPartnerHandler = (i: number) => {
    if (checkHandler()) return;

    const partner = partnersData[i];
    
    const dt = dayjs(partner.dt).utc();
    const lat = degToString(partner.place.lat, { isInternational: true, showSeconds: true, isLatitude: true });
    const lon = degToString(partner.place.lon, { isInternational: true, showSeconds: true, isLatitude: false });
  
    const convertedGMT = getGMTToString(partner.gmt ?? 0);

    const shortResult = `${partner.name};${dt.format('DD.MM.YYYY')};${dt.format('HH:mm:ss')};+${convertedGMT};${partner.place.name};${lat};${lon};${partner.gender};;`

    const result = {
      name: partner.name ?? '',
      dt: partner.dt,
      gmt: partner.gmt ?? 0,
      place: partner.place,
      gender: partner.gender
    }
    // store.instruments.setLSFormData(result);
  
    navigator.clipboard?.writeText(shortResult).then(_ => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    })
  };
  
  const pastePartnerHandler = (i: number) => {
    const data = store.instruments.getLSFormData();
    
    const updatedPartners = [...partnersData];
    updatedPartners[i] = {
      ...updatedPartners[i],
      name: data.name,
      dt: data.dt,
      gmt: data.gmt,
      place: data.place,
      gender: data.gender
    };
    setPartnersData(updatedPartners);
  
    setPasteSuccess(true);
    setTimeout(() => {
      setPasteSuccess(false);
    }, 3000);
  };

  const onFixedStars = useCallback(async (mode: string, list: string[], showWithObjects: boolean) => {
    setFixedStars(state => {
      return {
        ...state,
        [mode]: {
          list,
          showWithObjects
        }
      }
    });

    const profileId =  await updateAstroProfile({ fixedStars: { ...fixedStars, [mode]: { list, showWithObjects } } }, !Boolean('saveToBackend'));

    store.setActiveAstroProfileId(profileId)
  }, [fixedStars]);

  const onChangeShowHigherPlanets = async (val: boolean) => {
    setShowHigherPlanets(val);
    const profileId = await updateAstroProfile({ showHigherPlanets: val }, Boolean('saveToBackend'));
    store.setActiveAstroProfileId(profileId);
  }

  const handleSelect = async (option: any, index: number) => {
    const id = option.value;
    const targetForm: IFormData = await api.form(id);
    
    if(targetForm) {
      setPartnerVal({
        id,
        name: targetForm.name,
        gmt: targetForm.natal.gmt,
        dt: targetForm.natal.dt,
        place: targetForm.natal.place,
        // @ts-ignore
        gender: targetForm.natal.gender,
      }, index);
    } else {
      setPartnerVal({id}, index);
    }
  }

  const handleName = (val: string, index?: number) => {
    typeof index === 'number'
      ? setPartnerVal({name: val, id: undefined}, index)
      : setName(val);
  }

  const setPartnersGMT = (val: number, index?: number) => {
    typeof index === 'number' && setPartnerVal({gmt: val, id: undefined}, index)
  }

  const handleDateTime = (val: string, index?: number) => {
    if(typeof index === 'number') {
      setPartnerVal({
        dt: val,
        invalidDateTime: false,
        id: undefined,
        gmt: partnersData[index]?.place?.lat && partnersData[index].place.lon
          ? getGMT(val, partnersData[index].place.lat, partnersData[index].place.lon)
          : partnersData[index].gmt
      }, index);
    } else {
      setDateTime(val);
      if (place?.lat && place?.lon) {
        setGMT(getGMT(dateTime, place.lat, place.lon));
      }
    }
  }

  const handleInvalidDateTime = (val: boolean, index?: number) => {
    typeof index === 'number'
      ? setPartnerVal({invalidDateTime: val, id: undefined}, index)
      : setInvalidDateTime(val);
  }

  const handleGender = (val: Gender, index?: number) => {
    typeof index === 'number'
      ? setPartnerVal({gender: val, id: undefined}, index)
      : setGender(val);
  }

  const setPartnerVal = (obj: any, index: number) => {
    setPartnersData(partnersData => (
      [
        ...partnersData.slice(0, index),
        {
          ...partnersData[index],
          ...obj,
        },
        ...partnersData.slice(index + 1),
      ]
    ));
  }

  const addNewPartner = () => {
    setPartnersData([
      ...partnersData,
      {
        name: '',
        dt: new Date().toISOString(),
        gmt: 0,
        place: form.natal.place,
				opened: true,
      },
    ]);
  }

  const removePartner = (index: number) => {
    setPartnersData([
      ...partnersData.slice(0, index),
      ...partnersData.slice(index + 1),
    ]);
  }

  const onShowStarCatalog = () => {
    setShowObjectCatalog(true);
  }

  const onUpdateRelocations = (form: IFormData, activeRelocation?: RelocationsMode) => {
      setForm(_form => ({..._form, ...form}));
      activeRelocation && setActiveRelocation(activeRelocation);
    }

  return (<>
    <Container onSubmit={onSubmit} ref={formRef}>
      <Header>
        {info.icon}
        <Title>{t(info.title)}</Title>

        <ClipboardContainer>
          { isSupportClipboardApi && props.type !== 'synastry' &&
            <>
              <Tooltip vPosition='bottom' hPosition='left' text={copySuccess ? t("chronos.app.components.editForm.copied") : t("chronos.app.components.editForm.copyData")}>
                <CopyIcon onClick={copyHandler} />
              </Tooltip>
              <Tooltip vPosition='bottom' hPosition='left' text={pasteSuccess ? t("chronos.app.components.editForm.inserted") : t("chronos.app.components.editForm.insertData")}>
                <ClipboardIcon onClick={pasteHandler} />
              </Tooltip>
            </>
          }

          <CloseButton onClick={props.onClose} />
        </ClipboardContainer>
      </Header>

      <Main>
        {props.type !== 'synastry' && props.type !== 'relocation' &&
          <Section
            title={t("chronos.app.components.editForm.baseData")}
            isOpened={basicIsOpened.value}
            switch={() => sectionsChanged('basic', basicIsOpened.switch())}
            isLast={props.type !== 'horar'}
          >
            {props.type === 'horar' &&
              <Label>
                <span>{t("base.question")}</span>
                <Input
                  value={question}
                  onChange={setQuestion}
                  placeholder={t("base.questionInfo")}
                  autoFocus={true}
                />
              </Label>
            }

            <MainGroup
              className='main-group'
              type={props.type}
              natal={props.form.natal}
              place={place}
              setPlace={val => onPlaceChange(val)}
              dateTime={dateTime}
              setDateTime={onDateTimeChange}
              setInvalidDateTime={() => { setInvalidDateTime(true) }}
              gmt={gmt}
              setGMT={onChangeGMT}
              fixedGmt={userFixedGmt}
              cosmogram={cosmogram}
              setCosmogram={setCosmogram}
              showAspects={showAspects}
              setShowAspects={changeShowAspects}
              gender={gender}
              setGender={setGender}
              useNatalPlace={useNatalPlace}
              onChangeUseNatalPlace={onChangeUseNatalPlace}
              setDisableForm={setDisableForm}
            />
          </Section>

        }

        {props.type === 'relocation' && <>
            <RelacationsMainGroup 
              form={form}
              onUpdate={onUpdateRelocations}
              setDisableForm={setDisableForm}
              t={t}
            />
          </>
        }

        { props.type === 'synastry' && partnersData.length > 0 && 
          
          partnersData.map((partner, index, arr) => partner && (
              <Section
                key={`synastry_partner_${index}`}
                title={t(`astro.partner${index + 1}`)}
                isOpened={partner.opened}
                switch={() => switchPartnerOpened(index)}
								leftChevron={true}
								removable={arr.length > 1}
                copySuccess={copySuccess}
                pasteSuccess={pasteSuccess}
                onCopy={() => copyPartnerHandler(index)} 
                onPaste={() => pastePartnerHandler(index)}
								onDelete={() => removePartner(index)}
              >
                <MainGroup
                  key={`partner-group-${index}`}
                  className='main-group'
                  type="synastry"
                  natal={props.form.natal}
                  place={partner.place}
                  setPlace={val => onPlaceChange(val, index)}
                  dateTime={partner.dt}
                  setDateTime={val => handleDateTime(val, index)}
                  setInvalidDateTime={() => handleInvalidDateTime(true, index)}
                  gmt={partner.gmt ?? 0}
                  fixedGmt={partnersFixedGmt[index]}
                  setGMT={(val, auto) => onChangeGMT(val, auto!, index)}
                  gender={partner.gender}
                  setGender={val => handleGender(val, index)}
                  useNatalPlace={useNatalPlace}
                  onChangeUseNatalPlace={onChangeUseNatalPlace}
                  setDisableForm={setDisableForm}
                  name={partner.name}
                  setName={val => handleName(val, index)}
                  formOptions={formOptions}
                  selectedFormId={partnersData[index].id}
                  setSelectedFormId={val => handleSelect(val, index)}
									parentContainerRef={formRef}
                />
              </Section>
            ))
        }


        {props.type === 'synastry' && partnersData.length < MAX_PARTNERS_AMOUNT &&
          <AddPersonButton color='transparent' onClick={addNewPartner}>
            <PlusIcon />
            {t('chronos.app.components.editForm.addPartner')}
          </AddPersonButton>
        }
      </Main>

      {props.type == 'horar' &&
        <StyledSection
          title={t("chronos.app.components.editForm.additionalInfo")}
          isOpened={additionalIsOpened.value}
          switch={() => sectionsChanged('additional', additionalIsOpened.switch())}
          isLast={true}
        >
          <HousesSystemSelect
            title={t("chronos.app.components.editForm.houseSystem")}
            value={housesSystem}
            setValue={setHousesSystem}
          />
          <HigherPlanets>
            <span>{t("chronos.app.settings.showHigherPlanets.onTheMap")}</span>
            <StyledSwitcher checked={showHigherPlanets} onChange={onChangeShowHigherPlanets} />
          </HigherPlanets>
        </StyledSection>
      }

      

      { (!['syn_prognostics'].includes(props.type) && props.type !== 'relocation') &&
        <ObjectСatalog onClick={onShowStarCatalog}>
          <span>{t("chronos.app.settings.objectCatalog")}</span>
          <span><ChevronRightIcon/></span>
        </ObjectСatalog>
      }

      <Errors>
        {errors.map(e => <li key={e.text}>{t(e.text)}</li>)}
      </Errors>

      <Buttons>
        <Button type="submit" disabled={disableForm}>{t("base.save")}</Button>
        <Button onClick={props.onClose} color="transparentWithBorder">{t("base.cancel")}</Button>
      </Buttons>

      {showLoader && <LoaderLayout><StyledCircleLoader/></LoaderLayout>}
    </Container>

    { showObjectCatalog &&
      <CommonPopup
        icon={<StarIcon />}
        title={t("chronos.app.settings.objectCatalog")}
        onClose={() => setShowObjectCatalog(false)}
      >
        <StarCatalog
          list={StarData}
          selected={fixedStars}
          // @ts-ignore
          mode={props.type}
          dateTime={dateTime}
          width='45rem'
          height='24rem'
          onSelect={onFixedStars}
        />
      </CommonPopup>
    }
    {showConfirmation.value &&
      <Confirmation
        title={t("base.resetFormations")}
        submitText={t("base.confirm")}
        onSubmit={onSubmitForm}
        onCancel={showConfirmation.off}
      >
        <ConfirmationText>
          {t("base.resetFormationsInfo")}
        </ConfirmationText>
      </Confirmation>
    }
    </>
  )
});

export const Container = styled.form`
  position: absolute;
  left: 0;
  bottom: calc(100% + 0.5em);
  width: 24rem;
  box-sizing: border-box;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  border-radius: 6px;
  font-weight: 500;
  background: var(--workspace-widget-background);
  color: var(--text-secondary);
  box-shadow: var(--workspace-box-shadow);
  max-height: 90vh;
  overflow-y: auto;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  /* padding-right: 1.25rem; */
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--element-neutral);
  margin-bottom: 1rem;
  overflow: visible;

  & > svg:first-child {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    fill: var(--icon-secondary);
  }
`;

const Title = styled.span`
  font-size: 1.125rem;
  color: var(--text-primary);
  display: flex;
  margin-right: auto;
`;

const StyledSection = styled(Section)`
  padding-right: 1.25rem;
`;

const ClipboardContainer = styled.div`
  display: flex;

  margin-left: auto;

  span {
    display: flex;
  }

  span:not(:last-child) svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
  }
`;

export const Main = styled.main`

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 0.375rem;
  /* padding-right: 1.25rem; */

  & > button {
    flex: 1;
  }
`;

const ConfirmationText = styled.p`
  margin: 1em 0;
  color: var(--text-third);
  font-size: 0.875rem;
`;

export const Errors = styled.ul`
  list-style-type: none;
  margin-bottom: 1.25rem;
  padding: 0;
  color: var(--colors-red);
  font-size: 0.9em;
`;

const AddPersonButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  padding-left: 0;

  & > svg {
    height: 1.125rem;
    margin-right: 0.5rem;
  }

  & > span {
    line-height: 1;
  }
`;

const LayoutAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LoaderLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--disabled-layer-color);
  animation: ${LayoutAnimation} 0.2s ease-in-out;
`;

const StyledCircleLoader = styled(CircleLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const HigherPlanets = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

export const StyledSwitcher = styled(Switcher)`
  margin-left: auto;
`;

const ObjectСatalog = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    color: var(--text-primary);
  }
`;