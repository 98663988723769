import { showOnboarding } from 'src/lib/onboarding.js';

class EventInputInput extends HTMLElement {
  _shadow = this.attachShadow({ mode: 'closed' });
  
  get _t() {
    return window.translator;
  }

  connectedCallback() {
    const style = new CSSStyleSheet();

    style.replaceSync(`
      :host {
        width: 100%;
        position: relative;
        padding-left: 0.5em;
        display: flex;
        align-items: center;
      }

      input {
        width: 100%;
        color: var(--text-primary);
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background: none;
        outline: none;
        border: none;
        font-size: 0.9em;
        font-family: inherit;
        position: absolute;
      }

      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      
      #placeholder {
        opacity: 0.5;
        display: none;
      }


      @media (max-width: 480px) {
        :host {
          margin-bottom: 1em;
        }
        input {
          line-height: 2.5em;
        }
      }
    `);

    this._shadow.adoptedStyleSheets = [style];

    this._shadow.innerHTML = /* html*/`
      <input id="placeholder">
      <input id="input" type="text" placeholder="${this.getAttribute('placeholder')}">
    `;

    this._shadow.children.input.oninput = this.onInput;

    window.addEventListener('onboarding', this.onBoarding);
  }

  onInput = e => showOnboarding();

  disconnectedCallback() {
    window.removeEventListener('onboarding', this.onBoarding);
  }

  onBoarding = ({ detail: msg }) => {
    if (msg.id == 'event-input-input') {
      showOnboarding({
        target: this._shadow.children.input,
        summary: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.firstYouNeedToAddNewEvent"),
        description: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.firstYouNeedToAddNewEvent.description"),
        offsetY: 30,
        offsetX: 5,
        place: 'left'
      });
    }
  };

  show(v) {
    this.style.display = v ? 'flex' : 'none';
  }

  type() {
    return this._shadow.children.input.type;
  }

  setType(t) {
    const { placeholder, input } = this._shadow.children;

    // input.type = t
    input.dataset.type = t;

    if (t === 'date') {
      input.placeholder = this._t.t("chronos.app.instruments.widgetsrectification.eventInput.inputPlaceholder");
      input.addEventListener('input', mask);
    } else {
      input.placeholder = this._t.t("chronos.app.instruments.widgetsrectification.eventInput.startToAddNewEvent");
      input.removeEventListener('input', mask);
    }

    // placeholder.style.display = t == 'text' ? 'block' : 'none'
  }

  focus() {
    this._shadow.children.input.focus();
  }

  blur() {
    this._shadow.children.input.blur();
  }

  value() {
    return this._shadow.children.input.value;
  }

  setValue(v) {
    const { input } = this._shadow.children;
    if (input.type == 'text') {
      input.value = v;
    } else {
      input.valueAsDate = v;
    }
  }

  setOnInput(f) {
    this._shadow.children.input.oninput = f;
  }

  setPlaceholder(t) {
    const { placeholder } = this._shadow.children;
    placeholder.value = t;
  }

  setDisabled(v) {
    this._shadow.children.input.disabled = v;
  }
}

export function mask(event) {
  let matrix = '__.__.____',
    i = 0,
    def = matrix.replace(/\D/g, ''),
    val = this.value.replace(/\D/g, '');

  if (def.length >= val.length) { val = def }
  let value = matrix.replace(/./g, a => /[\d_]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a);

  let day = value.substr(0, 2);
  let month = value.substr(3, 2);
  let year = value.substr(6, 4);
  var daysInMonth = 32 - new Date(year, month - 1, 32).getDate();

  if (!isNaN(+day) && +day > daysInMonth) {
    day = daysInMonth;
    value = `${day}.${value.substr(3)}`;
  }
  if (day === '00') {
    day = '01';
    value = `${day}.${value.substr(3)}`;
  }

  if (!isNaN(+month) && +month > 12) {
    month = 12;
    value = `${day}.${month}.${value.substr(6)}`;
  }
  if (month === '00') {
    month = '01';
    value = `${day}.${month}.${value.substr(6)}`;
  }

  const currentDate = new Date();
  if (!isNaN(+year) && +year > 1000 && new Date(year, month - 1, day) > currentDate) {
    day = twoDigit(currentDate.getDate());
    month = twoDigit(currentDate.getMonth() + 1);
    year = twoDigit(currentDate.getFullYear());
    value = `${day}.${month}.${year}`;
  }

  if (!isNaN(year) && year > 1000 && year < 1200) {
    year = 1200;
    value = `${day}.${month}.${year}`;
  }

  this.value = value;

  if (event.type == 'blur') {
    // if (this.value.length == 2) this.value = ""
  } else { setCursorPosition(this.value.length, this) }
}

function setCursorPosition(pos, elem) {
  elem.focus();
  if (elem.setSelectionRange) { elem.setSelectionRange(pos, pos) }
  else if (elem.createTextRange) {
    var range = elem.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

function twoDigit(number) {
  if (number < 10) { return '0' + number }
  return number;
}

customElements.define('event-input-input', EventInputInput);
