import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Button, show, CircleMode, toDateTime, fitString, degToString, IFormData, makeForm, nowISOString, toDateTimeString } from 'src/libs';
import api from 'src/api';

import {clickOutsideHook, switcherHook} from 'src/hooks';
import { getFixedGmt, clearURLSearchParams, isCustomPlace, isCustomPlaceName, isCustomPlaceCoordinates } from 'src/utils';
import {goToMap, history} from 'src/router';
import FormSettings from 'src/components/EditForm/Common';
import { activeHousesSystem } from 'src/components/EditForm/utils'; 
import ShareForm from 'src/components/ShareForm';
import { BinIcon, ArrowLeftIcon, PersonIcon, ProcessorIcon, CopyIcon, ImportIcon as ShareIcon, ShevronDownIcon  } from 'src/assets/icons/system';
import { TmpMapIcon as TmpClock } from 'src/assets/icons/system/24';
import Confirmation from 'src/components/Confirmation';
import store from 'src/store';
import { ICard } from 'src/store/Dashboard';
import Modal from "../../Dashboard/TableView/components/Modal";
import FilteredPersonMenu from "../../Dashboard/TableView/components/ContextMenu/FilteredPersonMenu";
import ContextMenuUi from "../../../ui/ContextMenuUi";
import {MenuItem} from "../../../ui/ContextMenuUi/types";
import { useTranslation } from 'src/i18n/useTranslation';

import SaveAsForm from './SaveAsForm';
import HeaderContainer from './HeaderContainer';
import TopBar from '../IndiBanners/TopBar';
import { routes } from 'src/router';

function SaveMenu(props: {
  onAction(act: string): void;
  onClose(): void;
}) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { t } = useTranslation();
  clickOutsideHook(ref, props.onClose);

  const items = [
    { action: 'copy', icon: <CopyIcon />, title: t("chronos.app.saveYourself"), description: t("chronos.app.saveYourselfDescription") },
    { action: 'open', icon: <ProcessorIcon />, title: t("chronos.app.openMap"), description: t("chronos.app.openMapDescription") }
  ];

  return (
    <SaveMenuC
      ref={ref}
    >
      {items.map(i =>
        <li
          key={i.title}
          onClick={() => {
            props.onAction(i.action);
            props.onClose();
          }}
        >
          {i.icon}
          <div>
            <span>{i.title}</span>
            <span>{i.description}</span>
          </div>
        </li>
      )}
    </SaveMenuC>
  );
}

const SaveMenuC = styled.ul`
  list-style-type: none;
  margin: 0;
  position: absolute;
  background: var(--bg-100);
  padding: 0.25em;
  border-radius: 0.5em;
  top: calc(100% + 0.5em);
  right: 0;
  min-width: 20em;
  box-sizing: border-box;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;

  box-shadow: var(--workspace-box-shadow);

  & > li {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5em;
    border-radius: 0.25em;
    color: var(--text-primary);
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;

    & > svg {
      width: 1.25em;
      color: var(--icon-third);
      margin: 0 1em;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 0.9em;

      & :last-child {
        color: var(--text-secondary);
        margin-top: 0.5em;
        font-size: 0.75em;
      }
    }

    &:hover {
      background-color: var(--element-neutral)
    }
  }
`;


export default observer(function Header(props: {
  modes: CircleMode[];
  form: IFormData;
  onFormEdit(form: IFormData): void;
}) {
  const showSettings = switcherHook(false);
  const showSaveAs = switcherHook(false);
  const showDuplicate = switcherHook(false);
  const showShare = switcherHook(false);
  const showSaveMenu = switcherHook(false);
  const showOpenConfirmation = switcherHook(false);
  const { dashboard: dashboardStore } = store;
  const isInstruments = props.form.isTemporary;
  const t = useTranslation();
  const settingsWrapperRef = React.useRef<HTMLDivElement>(null);

  const [forceDropDownKey, setForceDropDownKey] = useState<number | undefined>(undefined);


  clickOutsideHook(settingsWrapperRef, showSettings.off);

  const onBack = () => {
    history.push(store.basicPage);
  };

  const onSharedMapOpen = async () => {
    const dfltForm = await api.form(-1);
    if (!dfltForm) { return }

    const form = { ...props.form };

    delete form.rectification;

    await api.updateForm({
      ...form,
      name: "chronos.app.tmpMap",
      isTemporary: true,
      id: dfltForm.id
    });

    showOpenConfirmation.off();

    goToMap(-1);
  };

  const onSaveAs = async (name: string, modes: string[]) => {
    const { id } = await api.addForm({
      ...makeForm({
        name,
        housesSystem: activeHousesSystem(),
      }, store.activeAstroProfile),
      isTemporary: false,
      natal: props.form.natal,
      synastry: modes.includes('synastry') ? props.form.synastry : undefined,
      prognostics: modes.includes('prognostics') ? props.form.prognostics : undefined,
      horar: modes.includes('horar') ? props.form.horar : undefined,
      // @ts-ignore
      partners: modes.includes('synastry') ? props.form.partners : undefined,
      settings: props.form.settings
    });

    store.dashboard.loadForms()

    showSaveAs.off();

    goToMap(id, props.modes);

    show({
      type: 'success',
      text: t.t("chronos.app.successMapSaveInfo")
    });

  };

  const onSaveMenu = async (act: string) => {
    if (act === 'copy') {
      const form = { ...props.form };

      delete form.rectification;

      if (form.isTemporary) {
        form.color = '#5E5E5E';
      }

      const { id } = await api.addForm({
        ...form,
        folderId: null,
        id: -1
      });

      show({
        type: 'success',
        text: t.t("chronos.app.successMapSaveInfo")
      });

      goToMap(id, props.modes);
    } else if (act === 'open') {
      showOpenConfirmation.on();
    }
  };

  const modes: string[] = [];

  if (props.form.synastry || props.form.partners?.length) { modes.push('synastry') }
  if (props.form.prognostics) { modes.push('prognostics') }
  if (props.form.horar) { modes.push('horar') }

  const changedDate = toDateTime(props.form.modified);
  changedDate.time = new Date(props.form.modified).toLocaleTimeString()
  const name = props.form.isTemporary ? t.t(routes.Instruments.title.tmpMap) : props.form.name;
  const { place, dt, gmt } = props.modes.includes('horar') ? props.form.horar! : props.form.natal;
  const { gmt: fixedGmt } = getFixedGmt({ dt, lat: place.lat, lon: place.lon, data: store.settings.user.profile?.fixedGmt });

  const placeCoords = `${degToString(place.lat, { isLatitude: true })} ${degToString(place.lon)}`;

  const [contextMenuUi, setContextMenuUi] = useState<React.ReactElement | null>(null);

  const onContextMenu = useCallback(dashboardStore.onContextMenuAction.bind(dashboardStore), []);

  useEffect(() => {
    if (!dashboardStore.forms) {
      dashboardStore.loadForms();
    }
  }, [dashboardStore.forms]);

  useEffect(() => {
    if (store.shareId) return setContextMenuUi(null);

    const toInstruments = async () => {
      const dfltForm = await api.form(-1)
      if (!dfltForm) { return }

      const currentForm = { ...props.form }

      delete currentForm.rectification

      await api.updateForm({
        ...currentForm,
        name: "chronos.app.tmpMap",
        isTemporary: true,
        id: dfltForm.id
      });

      goToMap(-1, props.modes);
    };

    const duplicate = async () => {
      showDuplicate.on()
    };

    const filtredForms = dashboardStore.forms?.filter((item: ICard) => (item.id !== props.form.id) && !item.isTemporary)

    const partner = <FilteredPersonMenu
        items={filtredForms}
        form={props.form}
        onSetForm={props.onFormEdit}
        onClose={() => { setForceDropDownKey(+new Date()) }}
      />;

      const contextMenuState = [
        [
          {
            icon: <TmpClock/>,
            title: t.t("chronos.app.toTheTmpMap"),
            action: toInstruments,
          },
          {
            icon: <CopyIcon/>,
            title: t.t("chronos.app.duplicateMap"),
            action: duplicate,
          },
          {
            icon: <PersonIcon/>,
            title: t.t("chronos.app.addPartner"),
            subMenu: partner,
          },
        ],
        [
          {
            icon: <ShareIcon/>,
            title: t.t("base.share"),
            action: () => showShare.on(),
          },
        ],
        [
          {
            icon: <BinIcon/>,
            title: t.t("chronos.app.removeCard"),
            className: 'drop',
            action: () => onContextMenu('card-drop', {cardId: props.form.id}),
          },
        ],
      ];

      const contextMenuStateForInstruments = contextMenuState.reduce((all: MenuItem[][], group: MenuItem[]) => {
        const filteredGroup = group.filter((item: MenuItem) => item.title !== "chronos.app.removeCard" && item.title !== "chronos.app.toTheTmpMap" && item.title !== "chronos.app.duplicateMap");
        if(filteredGroup.length > 0) all.push(filteredGroup);
        return all;
      }, []);

      setContextMenuUi(<ContextMenuUi forceClose={forceDropDownKey} groupsItems={isInstruments ? contextMenuStateForInstruments : contextMenuState}/>);

    // Если по какой то причине в зависимости хочется добавить props.form
    // то это вызывает слишком много запросов - надо делать другой подход
  }, [store.shareId, store.dashboard.forms, forceDropDownKey]);

  const authAfterSharedMap = () => {
    clearURLSearchParams('share');
    setTimeout(() => api.goToAuth(), 16);
  }

  return (
    <>
      <TopBar />
      <HeaderContainer>
        {store.settings.user.auth.id != -1 && <ArrowLeftIcon onClick={onBack} />}

        <SettingsWrapper ref={settingsWrapperRef}>
          <div className='name' onClick={
              props.form.access.isPersonal && !store.settings.user.isLimitedAccess
                ? (showSettings.value ? showSettings.off : showSettings.on) 
                : () => {}
            }>
            <span title={t.t(name)} className="name-for-print">{fitString(t.t(name), 48)}</span>
            { props.form.access.isPersonal && !store.settings.user.isLimitedAccess &&
              <StyledArrowDown /> 
            }
          </div>

          {showSettings.value &&
            <FormSettings
              form={props.form}
              onClose={showSettings.off}
              modes={props.modes}
              onSubmit={props.onFormEdit}
            />
          }
        </SettingsWrapper>

        {(props.form.access.isPersonal || props.form.access.showPersonalData) &&
          <>
            <span className="vertical"/> 

            <span className="place place-for-print">
              <span>{toDateTimeString(dt)},&nbsp;</span>
              {gmt != null && <span className={(fixedGmt !== null && !isCustomPlace(place)) ? 'not-auto' : ''}>{`(GMT${(fixedGmt || gmt) > 0 ? '+' : ' '}${fixedGmt || gmt})`}&nbsp;</span>}
              <span className={isCustomPlaceName(place) ? 'custom' : ''}>{place.name},&nbsp;</span>
              <span className={isCustomPlaceCoordinates(place) ? 'custom' : ''}>{placeCoords}</span>
            </span>
          </>
        }

        <Splitter />

        {props.form.isTemporary && props.form.access.isPersonal && !store.settings.user.isLimitedAccess &&
          <>
            <ChangedDate>{t.t("chronos.app.chenged")} {changedDate.date === toDateTime(nowISOString()).date ? t.t("base.today") : changedDate.date} {t.t("base.prepositions.in")} {changedDate.time}</ChangedDate>
            <Button onClick={showSaveAs.on} color='#73B330'>
              {t.t("base.saveAs")}
            </Button>
          </>
        }

        {/* {store.settings.user.auth.id != -1 && (props.form.access.isPersonal || props.form.access.showPersonalData) && !store.settings.user.isLimitedAccess && */}
          <ShareWrapper>
            <Button
              onClick={
                props.form.access.isPersonal ?
                  showShare.on :
                  props.form.access.showPersonalData ?
                    showSaveMenu.on :
                    () => {}
              }
              color='transparent'
              bordered
            >
              {props.form.access.isPersonal ?
                t.t("base.share") :
                props.form.access.showPersonalData ?
                  t.t("base.save") :
                  t.t("base.download")
              }
            </Button>

            {showSaveMenu.value && <SaveMenu
              onClose={showSaveMenu.off}
              onAction={onSaveMenu}
            />}

            {showShare.value && <ShareForm
              form={props.form}
              onClose={showShare.off}
              onUpdate={props.onFormEdit}
            />}
          </ShareWrapper>
        {/* } */}

        {store.settings.user.auth.id === -1 &&
          <Button
            onClick={authAfterSharedMap}
            color="accent"
            style={{
              marginLeft: '0.5em'
            }}
          >{t.t("base.signIn")}</Button>
        }

        {showSaveAs.value &&
          <SaveAsForm
            onClose={showSaveAs.off}
            onSubmit={onSaveAs}
            modes={modes}
          />
        }

        {showDuplicate.value &&
          <SaveAsForm
            onClose={showDuplicate.off}
            onSubmit={onSaveAs}
            modes={modes}
            title={props.form.name}
          />
        }

        {!store.settings.user.isLimitedAccess && contextMenuUi}
      </HeaderContainer>

      {showOpenConfirmation.value &&
        <Confirmation
          title={t.t("chronos.app.attention")}
          onSubmit={onSharedMapOpen}
          onCancel={showOpenConfirmation.off}
        >
          <p style={{ marginBottom: '2em' }}>{t.t("chronos.app.tmpMapWarning")}</p>
        </Confirmation>
      }

      <Modal />
    </>
  );
});


const Splitter = styled.div`
  margin: 0 auto;
`;

const SettingsWrapper = styled.div`
  position: relative;

  & > .name {
    display: flex;
    align-items: center;

    color: var(--text-primary);
    cursor: pointer;
    margin-left: 0.5em;

    & > span {
      /* margin-top: -0.2em; */
    }

    &:hover {
      opacity: 0.6;
    }
  }

`;

const ShareWrapper = styled.div`
  position: relative;
  margin-left: 0.75rem;
`;

const ChangedDate = styled.span`
  margin-right: 1em;
`;

const StyledArrowDown = styled(ShevronDownIcon)`
  fill: var(--text-secondary);
  height: 1.5em;
  margin-left: 0.5em;
  margin-top: 0.1em;
`;
