import React from 'react';
import styled, { css } from 'styled-components';

import ym from 'react-yandex-metrika';

import { Button, IGenInfo } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { setDayWithOptions } from 'date-fns/fp';

export default function Info(props: {
  onAdd(): void;
  onBuild(): Promise<any>;
  info: IGenInfo;
}
) {
  const { t } = useTranslation();

  const [disabled, setDisabled] = React.useState(false);

  const onBuild = async () => {
    setDisabled(true);
    await props.onBuild();
    setDisabled(false);
  };
  
  return (
    <Container>
      <Count count={props.info?.count}>
        {props.info?.count ?? 0}
      </Count>

      {t("chronos.app.instruments.widgets.genDialog.constructions")}

      <InfoC>
        {props.info.count
          ? t("chronos.app.instruments.widgets.genDialog.warning")
          : t("chronos.app.instruments.widgets.genDialog.warning.info")
        }
      </InfoC>

      <Buttons>
        <Button
          color={props.info.count > 0 ? 'transparent' : 'accent'}
          onClick={() => {
            props.onAdd();
            ym('reachGoal', 'open-add-buildings-dialog');
          }}
          disabled={disabled}
        >{t("chronos.app.settings.addMore")}</Button>
        {props.info.count > 0 &&
          <Button
            onClick={onBuild}
            disabled={disabled}
          >{t("chronos.app.instruments.widgets.genDialog.build")}</Button>
        }
      </Buttons>
    </Container>
  );
}

const Container = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Count = styled.div<{ count: number }>`
  font-size: 2.5rem;

  width: calc(1em * 2);
  height: calc(1em * 2);
  margin: 2rem;
  margin-bottom: 0;

  text-align: center;
  line-height: calc(1em * 2);
  vertical-align: middle;

  color: var(--text-primary);
  border: 2px solid var(--text-third);
  border-radius: 50%;
  padding: 1rem;

  ${props => props.count == 0 && css`
    border: 2px solid var(--colors-red);
  `}
`;

const InfoC = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: var(--text-secondary);
  margin: 1em 0;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 0.5em;

  & :first-child {
    margin-right: 1em;
  }
`;
