import React from 'react';
import styled, { css } from 'styled-components';

import { IFormData, RelocationsMode, ISynastryPartnerData } from 'src/libs';

import MainGroup from 'src/components/EditForm/MainGroup';
import { Section } from 'src/components/EditForm/Section';
import { PlusIcon } from 'src/assets/icons/system';


export default function RelacationsMainGroup({
    form,
    onUpdate,
    setDisableForm,
    t
}: {
    form: IFormData,
    onUpdate: (form: IFormData, activeRelocation?: RelocationsMode) => void,
    setDisableForm: (val: boolean) => void,
    t: (key: string) => string
}) {


    const [localForm, setLocalForm] = React.useState<IFormData>(form);
    const [activeRelocation, setActiveRelocation] = React.useState<RelocationsMode>(('relocation_natal'));

    React.useEffect(() => {
        const _relocations = form?.settings?.modes.relocation?.filter(mode => mode != 'relocation_natal') || [];
        if (_relocations.length === 0) {
            onAdd();
        }
    }, [form]);

    
    const onChangePlace = (value: any) => {
        const _form = JSON.parse(JSON.stringify(localForm));
        _form.relocation[activeRelocation].place = value;
        setLocalForm(_form);
        onUpdate(_form);
    }

    const onSwitch = (relocationMode: RelocationsMode, optForm?: IFormData, skipPrev = false) => {
        const _form = JSON.parse(JSON.stringify(optForm ?? localForm));
        
        if (_form.relocation) {

            if (!skipPrev && activeRelocation !== 'relocation_natal') {
                const prev = _form.relocation[activeRelocation];
        
                // if (prev) {
                //   prev.dt = dateTime;
                //   prev.gmt = gmt;
                //   prev.place = place;
                // }
              }

            const next = _form.relocation[relocationMode];
    
            if (next) {
                // onChange('place')(next.place)
                // onChange('dt')(next.dt)
                // onChangeGMT(next.gmt ?? 0, true)
            }
        }
    
        setLocalForm(_form);
        setActiveRelocation(relocationMode);
        onUpdate(_form, relocationMode);
    }

    const onAdd = () => {
        const _form = JSON.parse(JSON.stringify(localForm));
        const _formNatal = JSON.parse(JSON.stringify(_form.natal));

        const relocation = _form.relocation ?? (_form.relocation = {});
        
        let relLength = Object.keys(relocation).length;
        
        if (relLength === 0) {
            if (!_form.settings?.modes.relocation.includes('relocation_natal')) {
                _form.settings?.modes.relocation.push('relocation_natal');
            }
            relocation['relocation_natal'] = _formNatal;
            relLength++;
        }

        const name = 'relocation' + relLength.toString() as RelocationsMode;

        relocation[name] = {
            ..._formNatal,
            place: { name: '' },
        };
        _form.settings!.modes.relocation = _form.settings?.modes.relocation || ['relocation_natal'];
        _form.settings?.modes.relocation.push(name);


        onSwitch(name, _form);
        
    }

    const onDelete = (deletedRelocation: RelocationsMode) => {
        const _form = JSON.parse(JSON.stringify(localForm));
            const delInd = parseInt(deletedRelocation.substring('relocation'.length));
            const relocation: any = {};
        
            _form.settings!.modes.relocation = _form.settings!.modes.relocation
              .filter((m: RelocationsMode) => m != deletedRelocation)
              .map((m: RelocationsMode) => {
                const src = m;
        
                if (m.startsWith('relocation')) {
                  const ind = parseInt(m.substring('relocation'.length));
        
                  if (ind > delInd) {
                    m = `relocation${ind - 1}` as RelocationsMode;
                  }
                }
        
                relocation[m] = (_form.relocation as any)[src];
        
                return m;
              });
        
            _form.relocation = {...relocation};
        
            onSwitch(`relocation${delInd > 1 ? delInd - 1 : delInd}` as RelocationsMode, _form);
    }

    const existingRelocations = React.useMemo(() => {
        return localForm?.settings?.modes.relocation?.filter(mode => mode != 'relocation_natal') || [];;
    }, [localForm]);

    const _relocationData = React.useMemo(() => {
        return localForm.relocation 
            ? localForm.relocation[activeRelocation as RelocationsMode] : ({} as ISynastryPartnerData)
    }, [localForm, activeRelocation])


    const hasAllRelocationsPlace = React.useMemo(() => { 
        return Object.values(localForm.relocation || {}).every(({ place }) => place && place.name && place.lat && place.lon);
      }, [localForm]);


    const _sections = React.useMemo(() => {

        const _result = existingRelocations?.map((_relocation, i) => {
            return <Section
                key={`relocation_${i}`}
                title={`${t("chronos.app.components.editForm.mainGroup.place")} ${_relocation.replace(/\D/g, '')}`} // RU
                isOpened={_relocation === activeRelocation}
                switch={() => hasAllRelocationsPlace && onSwitch(_relocation as RelocationsMode)}
                leftChevron={true}
                removable={existingRelocations.length > 1}
                onDelete={() => onDelete(_relocation as RelocationsMode)}
                isLast={i === (existingRelocations.length ?? 0) - 1}
                >
                <MainGroup
                    className='main-group'
                    type='relocation'
                    natal={form.natal}
                    place={_relocationData?.place!}
                    setPlace={onChangePlace}
                    dateTime={_relocationData?.dt!}
                    gmt={_relocationData?.gmt ?? 0}
                    fixedGmt={null}
                    cosmogram={form.cosmogram}
                    setDisableForm={setDisableForm}
                />    
            </Section>
        })

        return _result;
    }, [existingRelocations, activeRelocation])


    return (
        <>
            { _sections }

            { existingRelocations.length <= 8 &&
                <AddNewPlace 
                    onClick={onAdd} 
                    disabled={!hasAllRelocationsPlace}
                >
                    <PlusIcon />{t("astro.relocation.addNewPlace")}
                </AddNewPlace> // RU
            }
        </>
    )
}


const AddNewPlace = styled.div<{ disabled?: boolean; }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.37;
    pointer-events: none;
  `};
`;