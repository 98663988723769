import React from "react";
import { throttle } from "lodash"; 
import api from 'src/api';

import {
  IFormData, ICalculation, CircleMode, RelocationsMode, ISynastryPartnerData,
  IAstroSettings, localTime, getLocalTime, houseAspects, IBaseData, isSynastry, getDirections, 
  directionShift, ObjectType,
  PrognosticsMode,
  IMap,
  IObject,
  IAspect,
  IAspectPattern,
  AspectsPatterns,
  IFixedStar,
  IWidgetsItem,
  IMapDescription
} from "src/libs";

import astro from "src/astro";

import { IHighlightsData } from 'src/components/Circle';
import { TWidgetCircleMode } from './Widgets/AspectsTable/ModeSelectorTabs';
import { IPrompt } from './Widgets/Prompts/types';


import { IStrongs } from './Widgets/Widget';

import {
  NatalIcon,
  HorarIcon,
  SoulIcon,
  CompatibilityIcon,
  PartnerIcon,
  Partner1Icon,
  Partner2Icon,
  Partner3Icon,
  Partner4Icon,
  Partner5Icon,
  Partner6Icon,
  Partner7Icon,
  Partner8Icon,
  Partner9Icon,
  DirectionsIcon,
  SolarsIcon,
  TransitsIcon,
  ProgProgIcon,
  ProgNatalIcon,
  RelocationIcon
} from 'src/assets/icons/maps';


import { Dispositors } from "src/helpers/Dispositors";
import { MinorAspectsModes } from "src/components/Circle/Aspects";
import { IMapIndicatorState } from "src/store/InstrumentsStore";
import { formGenLS, IGen } from "src/helpers/localStorage";

const partnerIcons: {
  [key: string]: any,
} = {
  partner1: Partner1Icon,
  partner2: Partner2Icon,
  partner3: Partner3Icon,
  partner4: Partner4Icon,
  partner5: Partner5Icon,
  partner6: Partner6Icon,
  partner7: Partner7Icon,
  partner8: Partner8Icon,
  partner9: Partner9Icon,
};

export const modeMetaData: {
  [key: string]: {
    title: string;
    showTab: boolean;
    icon?: React.ComponentType;
  }
} = {
  directions: {
    title: 'astro.directions',
    showTab: false,
    icon: DirectionsIcon,
  },
  horar: {
    title: 'astro.horar',
    showTab: true,
    icon: HorarIcon,
  },
  soul: {
    title: 'astro.formulaSoul',
    showTab: true,
    icon: SoulIcon,
  },
  natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  syn_natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  transits: {
    title: 'astro.transits',
    showTab: false,
    icon: TransitsIcon,
  },
  solars: {
    title: 'astro.solars',
    showTab: false,
    icon: SolarsIcon,
  },
  prog_natal: {
    title: 'astro.progNatal',
    showTab: false,
    icon: ProgNatalIcon,
  },
  prog_prog: {
    title: 'astro.progProg',
    showTab: false,
    icon: ProgProgIcon,
  },
  relocation_natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  syn_prognostics: {
    title: 'astro.transits',
    showTab: true,
    icon: TransitsIcon,
  },
};

export const throttleUpdateForm = throttle(async function(f: IFormData) {
  // цепляем gen для формы из ls на всякий случай
  f.gen = formGenLS.get(f.id, f.gen) as IGen;
  
  return api.updateForm(f);
}, 1500, {
  leading: false
});

export function getModeByList(mode: CircleMode, modes: CircleMode[]) {
  return (modes.includes(mode) ? mode : modes[0]) || 'natal';
}

export const INNER_CIRCLE_PROPORTION = 1.25;

export const initialStrongs = {
  planets: [],
  houses: {
    houses: [],
    planets: [],
    power: []
  },
  aspects: [],
  objects: [],
  objectsExt: [],
};

export interface IHighlight {
  map: CircleMode;
  type: 'aspect' | 'object' | 'object_ext' | 'house' | 'house_ext' | 'object_outer' | 'house_outer' | 'sign' | 'star' | 'star_ext';
  id: number | string;
}

export interface IWidgetsItemExt extends IWidgetsItem {
  canUseFree?: boolean
}
export interface IInitialState {
  access: { type: 'private' | string, showPersonalData: boolean };
  widgetMode: TWidgetCircleMode;
  currentMode: CircleMode;
  modes: CircleMode[];
  maps: IMap[] | [];
  natalMap: IMap | null;
  partnersMaps: { [key: string]: IMap } | null;
  strongs: IStrongs;
  synastryModes: CircleMode[];
  prognosticsModes: CircleMode[];
  relocationModes: CircleMode[];
  widgets: IWidgetsItemExt[];
  form: IFormData | null;
  natal: IMap | null;
  highlights: IHighlightsData;
  description: IMapDescription | null;
  accessError: string;
  descriptionSoul: number | null;
  prompt: IPrompt | null;
  pinnedAspects: number[];
  skipUpdate: boolean;
  minorAspectsMode: MinorAspectsModes;
}


export const initialState = (modes: CircleMode[]): IInitialState => ({
    widgetMode: getModeByList('natal', modes),
    currentMode: getModeByList('natal', modes),
    modes,
    maps: [],
    natalMap: null,
    partnersMaps: null,
    strongs: initialStrongs,
    synastryModes: ['syn_natal'] as CircleMode[],
    prognosticsModes: ['directions', 'solars', 'transits', 'prog_natal'] as CircleMode[],
    relocationModes: ['relocation_natal'] as CircleMode[],
    widgets: [
      {
        order: 0,
        id: 'aspects-table',
        isActive: false,
        canUseFree: true
      },
      {
        order: 1,
        id: 'coordinates-of-planets-and-houses',
        isActive: false,
        canUseFree: false
      },
      {
        order: 2,
        id: 'strong-objects',
        isActive: false,
        canUseFree: false
      },
      {
        order: 3,
        id: 'house-formulas',
        isActive: false,
        canUseFree: false
      },
      {
        order: 4,
        id: 'analyze',
        isActive: false,
        canUseFree: false
      },
            {
        order: 5,
        id: 'dispositors',
        isActive: false,
        canUseFree: false
      },
      {
        order: 6,
        id: 'orbise-corrector',
        isActive: false,
        canUseFree: false
      },
      {
        order: 7,
        id: 'rectification',
        isActive: true,
        canUseFree: true
      },
      {
        order: 8,
        id: 'devplan',
        isActive: true,
        canUseFree: true
      },
      {
        order: 9,
        id: 'autoprediction',
        isActive: true,
        canUseFree: true
      },
      {
        order: 10,
        id: 'interpretation',
        isActive: false,
        canUseFree: false
      },
      {
        order: 11,
        id: 'horar-events',
        isActive: false,
        canUseFree: false
      },
      {
        order: 12,
        id: 'formula-strength',
        isActive: false,
        canUseFree: false
      },
      {
        order: 13,
        id: 'ephemeris-formulas',
        isActive: false,
        canUseFree: false
      },
      {
        order: 14,
        id: 'formula-years',
        isActive: false,
        canUseFree: false
      },
      {
        order: 15,
        id: 'horar-speed',
        isActive: false,
        canUseFree: false
      },
      {
        order: 16,
        id: 'horar-essentials',
        isActive: false,
        canUseFree: false
      },
      {
        order: 17,
        id: 'formula-soul',
        isActive: false,
        canUseFree: false
      },
      {
        order: 18,
        id: 'interpretation-soul',
        isActive: false,
        canUseFree: false
      },
      {
        order: 19,
        id: 'horar-analysis',
        isActive: false,
        canUseFree: false
      },
      {
        order: 20,
        id: 'horar-light',
        isActive: false,
        canUseFree: false
      // },
      // {
      //   order: 21,
      //   id: 'table-prediction2',
      //   isActive: false,
      //   canUseFree: false
      }
    ],
    form: null,
    natal: null,
    highlights: {
      items: [],
      aspects: []
    },
    description: null,
    descriptionSoul: null,
    prompt: null,
    pinnedAspects: [],
    skipUpdate: true,
    accessError: '',
    access: {
      type: 'private',
      showPersonalData: true
    },
    minorAspectsMode: MinorAspectsModes.School
  });


export const isSynNatal = (mode: CircleMode) => /^syn_natal\d*$/.test(mode);

export const isCompatibility = (mode: CircleMode) => /^compatibility\d*$/.test(mode);

export const isPartner = (mode: CircleMode) => /^partner\d+$/.test(mode);

export const isRelocation = (mode: CircleMode) => /^relocation\d+$/.test(mode);

export const prognosticModes = ['directions', 'solars', 'transits', 'prog_natal']; //prognostics!!!!

export const progressionsTitle: { [key: string]: string } = prognosticModes.reduce((acc, mode, idx) => {
  (acc as any)[mode as string] = modeMetaData[mode].title
  return acc;
}, {});

export const isPrognostics = (mode: CircleMode) => prognosticModes.includes(mode);

export const compatibilityToPartner = (mode: CircleMode) => mode.replace('compatibility', 'partner') as CircleMode;

export const getModeNumber = (mode: CircleMode) => isPartner(mode) || isCompatibility(mode) ? +mode.replace(/\D/g, '') : undefined;

export const getModeMetaData = (mode: CircleMode, form?: IFormData) => {

  if (isPartner(mode)) {
    const index = +(mode.slice(-1)) - 1
    const title = form?.partners ? form?.partners[index]?.name : `astro.${mode}`

    return {
      title,
      showTab: true,
      icon: partnerIcons[mode] || PartnerIcon,
    };
  }

  if (isCompatibility(mode)) {
    return {
      title: `astro.${mode}`,
      showTab: true,
      icon: CompatibilityIcon,
    };
  }

  if (isRelocation(mode)) {
    if (mode === 'relocation_natal') {
      return modeMetaData?.[mode] || {};
    } else {
      return {
        title: form?.relocation?.[mode as RelocationsMode]?.place.name.split(',')[0],
        showTab: true,
        icon: RelocationIcon,
      };
    }
  }

  return modeMetaData?.[mode] || {};
}

export type TModeData = ISynastryPartnerData & { forMode: CircleMode };

export interface IFixedStarExt extends IFixedStar {
  constellation: string,
  constellationRu: string;
  sign: string;
  signRu: string;
  isHorar?: boolean;
}

export const getModeData = (mode: CircleMode, form: IFormData): TModeData | null => {

  if (isPartner(mode) || isCompatibility(mode)) {
    const num = getModeNumber(mode);
    if (!num || !form.partners?.[num - 1]) {
      return null;
    }
    return {...(form.partners[num - 1] as ISynastryPartnerData), forMode: isCompatibility(mode) ? (compatibilityToPartner(mode)) : mode};
  }

  if (mode === 'horar') {
    return form.horar 
      ? {...(form.horar as ISynastryPartnerData), forMode: mode} 
      : null;
  }

  if (isPrognostics(mode)) {
    return form.prognostics
      ? {...(form.prognostics as ISynastryPartnerData), forMode: mode}
      : null;
  }

  if (mode === 'syn_prognostics') {
    return form.syn_prognostics
      ? {...(form.syn_prognostics as ISynastryPartnerData), forMode: mode}
      : null;
  }

  return (
    form.natal 
      ? {...(form.natal as ISynastryPartnerData), forMode: 'natal'} 
      : null
  );
}

export const getSynastryModes = (form: IFormData) => {
  let synastryModes: CircleMode[] = []

  form.partners.forEach((partner: ISynastryPartnerData, index: number) => {
    if (!synastryModes.includes(`partner${index + 1}`)) {
      synastryModes.push(`partner${index + 1}`);
    }
    if (!synastryModes.includes(`compatibility${index + 1}`)) {
      synastryModes.push(`compatibility${index + 1}`);
    }
  });

  return synastryModes
}

async function calcSoul(dt: string, gmt: number | null, lat: number, lon: number) {
  const data = await astro.natal(getLocalTime(dt, gmt, lat, lon), lat, lon);

  const disp = Dispositors(data.objects);

  return {
    ...data,
    ...disp
  };
}

export function getBaseModeByMode(mode: CircleMode): string {
  let key: string = 'natal';

  if (mode === 'horar') { key = 'horar' }
      else if (isSynastry(mode) && mode !== 'syn_natal') { key = 'synastry' }
      else if (['natal', 'syn_natal'].includes(mode)) { key = 'natal' }
      else if (isPrognostics(mode)) { key = 'prognostics' }
  
  return key;
}

export function calcNatalAspectsWithFloatingOrbiseCorrector(aspects: IAspect[], natal: ICalculation, floatingOrbise: number): IAspect[] {
  if (!floatingOrbise) return aspects;

  let newAspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise });
  const newConfigurations = new AspectsPatterns(newAspects.filter(item => item.obj1 < 10 && item.obj2 < 10)).findPatterns() as IAspectPattern[];
  const isSameAspects = (aspect1: IAspect, aspect2: IAspect) => (aspect1.obj1 === aspect2.obj1 && aspect1.obj2 === aspect2.obj2) || (aspect1.obj1 === aspect2.obj2 && aspect1.obj2 === aspect2.obj1);
  const aspectInConfigurations = ((aspect: IAspect, configurations: IAspectPattern[]) => configurations.find((configuration: IAspectPattern) => configuration.aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect))));

  const aspectInArrayOfAspects = ((aspect: IAspect, aspects: IAspect[]) => aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect)));

  newAspects = newAspects.map(a => {
    let aspect = a;
    // Если аспект не входит в конфигурации, но при этом уже был, то снимаем с него орбисы и всё всё всё, дабы он был прежним
    if (aspectInArrayOfAspects(a, aspects) && !aspectInConfigurations(a, newConfigurations)) {
      aspect = aspects.find((aspect: IAspect) => isSameAspects(a, aspect)) as IAspect;
    }

    return aspect;
  });
  aspects = newAspects.filter(aspect => aspectInConfigurations(aspect, newConfigurations) || aspectInArrayOfAspects(aspect, aspects));
  // Ставим индексы и id на место
  aspects = aspects.map((item, i) => {
    item.id = i;
    return item;
  });

  return aspects;
}

export type TCalculationResult = Omit<IMap, 'mode'> & { soulObjects?: Array<number | null>; name?: string }

export async function calculation(mode: CircleMode, form: IFormData, activeAstroProfile?: IAstroSettings, showHorarAspects?: boolean, mapIndicator?: IMapIndicatorState): Promise<TCalculationResult> {
  const isNatal = ['natal', 'syn_natal', 'relocation_natal'].includes(mode)

  const astroProfile = activeAstroProfile;
  const floatingOrbise = (astroProfile && astroProfile.closureConfig) ?? 0

  if (astroProfile) {
    const { maps } = astro.settings;

    astro.settings = {
      ...JSON.parse(JSON.stringify(astroProfile)),
      housesSystem: form.housesSystem || { common: 0, northern: 3, horar: 2 }
    };

    if (maps) {
      Object.keys(maps).forEach(k => {
        (astro.settings.maps as any)[k].orbiseCorrector = (maps as any)[k].orbiseCorrector;
      });
    }
  }

  const ErisObjectNatal = await astro.object(localTime(form.natal), 136199 + 10000);

  const natal: ICalculation = ['soul'].includes(mode)
    ? {
      houses: [],
      objects: []
    }
    : await astro.natal(
        localTime(form.natal),
        form.natal.place.lat,
        form.natal.place.lon,
        form.cosmogram
      );

  if (isNatal) {
    let aspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise: 0 });
    aspects = calcNatalAspectsWithFloatingOrbiseCorrector(aspects, natal, floatingOrbise);

    if (mode === 'relocation_natal') {
      aspects.push(...houseAspects(natal.objects, natal.houses, aspects.length, astroProfile?.id === 1 ? 5 : 4).map((a: IAspect) => {
        const z = a.obj1;
        a.obj1 = a.obj2;
        a.obj2 = z;
        return a;
      }));
    }

    return {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects,
      objectsExt: [],
      housesExt: [],
      objectsOuter: [],
      housesOuter: []
    };
  }


  if (mode === 'horar') {
    if (!form.horar) throw new Error('Empty horar data');

    const horar = await astro.horar(
      localTime(form.horar),
      form.horar.place.lat,
      form.horar.place.lon
    );

    const isRounded = false;
    const aspects = showHorarAspects ? astro.natalAspects(horar.objects, undefined, undefined, isRounded) : [];

    const ErisObjectHorar = await astro.object(localTime(form.horar), 136199 + 10000);

    return {
      ...horar,
      objects: [...horar.objects, ErisObjectHorar],
      aspects: aspects,
      objectsExt: [],
      housesExt: [],
      objectsOuter: [],
      housesOuter: []
    };
  }

  if (mode === 'soul') {
    const soul = await calcSoul(
      form.natal.dt,
      form.natal.gmt,
      form.natal.place.lat,
      form.natal.place.lon
    );
    // @ts-ignore
    return {
      ...soul,
      aspects: [],
      soulObjects: soul.objectsExt,
      housesExt: [],
      objectsOuter: [],
      housesOuter: []
    };
  }

  if (isSynastry(mode)) {
    const partners = form.partners?.length
      ? form.partners
      : form.synastry
        ? [form.synastry]
        : null;

    if (!partners) { throw new Error('Empty synastry data') };

    const partnerIndex = (Number(mode.slice(-1)) - 1) || 0;
    const partner = partners[partnerIndex];
    const partnerExt = mapIndicator?.partnerExt[mode] ?? true;
    const partnerNatal = mapIndicator?.partnerNatal[mode] ?? true;
    const { compatibilityTransits, compatibilityReversed } = mapIndicator!;
    

    if (!partner) { throw new Error('Empty synastry data') };

    const ErisObjectPartner = await astro.object(localTime(partner), 136199 + 10000);

    // Синастрия
    if (isCompatibility(mode)) {

      const ext = await astro.natal(
        localTime(partner),
        partner.place.lat,
        partner.place.lon,
        false
      );

      let synastryAspects: IAspect[] = [];
      let ErisObjectTransits: IObject | null = null;

      const compatibilityTransitMode = compatibilityTransits[mode].transitMode;

      let compatibilityTransit: ICalculation | null = null;
      let objectsOuter: IObject[] = [];
      let housesOuter: number[] = [];
      let transitPartnerAspects: IAspect[] = [];
      let transitNatalAspects: IAspect[] = [];

      // считаем синастрические аспекты с учетом реверса совместимости
      if (!compatibilityReversed) {
        synastryAspects = astro.synastryAspects(natal.objects, ext.objects)
      } else {
        synastryAspects = astro.synastryAspects(ext.objects, natal.objects)
      }
      
      if (form.syn_prognostics) {
        let { dt: progDt, gmt, place: { lat, lon } } = form.syn_prognostics as IBaseData;
        progDt = getLocalTime(progDt, gmt, lat, lon);

        compatibilityTransit = await astro.natal(progDt, lat, lon, false);
        ErisObjectTransits = await astro.object(progDt, 136199 + 10000)

        // Показывать транзиты для Совместимости или нет
        if (compatibilityTransits[mode].showTransit) {
          
          objectsOuter = [...(compatibilityTransit?.objects || []), ErisObjectTransits] as IObject[];
          housesOuter = [...(compatibilityTransit?.houses || [])];
          
          ({
            transitPartnerAspects,
            transitNatalAspects
          } = astro.synastryTransitAspects(
            objectsOuter, // транзит
            housesOuter, 
            (!compatibilityReversed ? ext.objects : natal.objects),
            (!compatibilityReversed ? ext.houses : natal.houses),
            (!compatibilityReversed ? natal.objects : ext.objects),
            (!compatibilityReversed ? natal.houses : ext.houses),
            synastryAspects,
            compatibilityTransitMode
          ));

          // debugger
        } else {
          objectsOuter = [];
          housesOuter = [];
          transitPartnerAspects = [];
          transitNatalAspects = [];
        }
      }
      
      if (compatibilityReversed) {
        return {
          ...ext,
          objects: [...ext.objects, ErisObjectPartner],
          aspects: [...synastryAspects, ...transitPartnerAspects, ...transitNatalAspects],
          objectsExt: [...natal.objects, ErisObjectNatal],
          housesExt: natal.houses,
          objectsOuter,
          housesOuter,
          transitPartnerAspects,
          transitNatalAspects,
          name: 'natal'
        };
      } else {
        return {
          ...natal,
          objects: [...natal.objects, ErisObjectNatal],
          aspects: [...synastryAspects, ...transitPartnerAspects, ...transitNatalAspects], 
          objectsExt: [...ext.objects, ErisObjectPartner],
          housesExt: ext.houses,
          objectsOuter,
          housesOuter,
          transitPartnerAspects,
          transitNatalAspects,
          name: partner.name
        };
      }

    } else {
      const partnerNat = await astro.natal(
        localTime(partner),
        partner.place.lat,
        partner.place.lon,
        false
      );
      if (partnerExt && partnerNatal) {
        return {
          ...natal,
          objects: [...natal.objects, ErisObjectNatal],
          aspects: astro.synastryAspects(natal.objects, partnerNat.objects),
          objectsExt: [...partnerNat.objects, ErisObjectPartner],
          housesExt: partnerNat.houses,
          objectsOuter: [],
          housesOuter: [],
          name: partner.name
        };
      } else if (partnerExt && !partnerNatal) {
        return {
          ...partnerNat,
          objects: [...partnerNat.objects, ErisObjectPartner],
          aspects: astro.natalAspects(partnerNat.objects, undefined, partnerNat.houses),
          objectsExt: [],
          housesExt: [],
          objectsOuter: [],
          housesOuter: [],
          name: partner.name
        };
      } else {
        return {
          ...natal,
          objects: [...natal.objects, ErisObjectNatal],
          aspects: astro.natalAspects(natal.objects),
          objectsExt: [],
          housesExt: [],
          objectsOuter: [],
          housesOuter: [],
          name: partner.name
        };
      }
    }
  }

  // debugger
  if (isRelocation(mode)) {
    const d = form.relocation?.[mode as RelocationsMode] ?? form.natal;

    const relocation = await astro.natal(
      localTime(d),
      d.place.lat,
      d.place.lon,
    );

    const natal = await astro.natal(
      localTime(form.natal),
      form.natal.place.lat,
      form.natal.place.lon,
    );

    let aspects = astro.natalAspects(relocation.objects, undefined, undefined, undefined, { floatingOrbise: 0 });
    aspects = calcNatalAspectsWithFloatingOrbiseCorrector(aspects, natal, floatingOrbise);
    
    const ErisObjectRelocation = await astro.object(localTime(d), 136199 + 10000);

    return {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects: [
        ...aspects,
        ...houseAspects(relocation.objects, relocation.houses, aspects.length, astroProfile?.id === 1 ? 5 : 4).map((a: IAspect) => {
          const z = a.obj1;
          a.obj1 = a.obj2;
          a.obj2 = z;
          return a;
        }),
      ],
      objectsExt: [...relocation.objects, ErisObjectRelocation],
      housesExt: relocation.houses,
      objectsOuter: [],
      housesOuter: []
    };
  }

  if (!form.prognostics) throw new Error('Empty prognostics data');

  let { dt: progDt, gmt, place: { lat, lon } } = form.prognostics as IBaseData;
  progDt = getLocalTime(progDt, gmt, lat, lon);

  const ext: ICalculation = await (async () => {
    switch (mode) {
      case 'transits': return await astro.natal(progDt, lat, lon, false);
      case 'directions': return getDirections(natal, localTime(form.natal), progDt);
      case 'solars': return await astro.solars(natal.objects[ObjectType.Sun].lon, progDt, lat, lon);
      case 'prog_prog':
      case 'prog_natal': return await astro.progressions(localTime(form.natal), progDt, lat, lon);
      default: return { houses: [], objects: [] }
    }
  })();

  // @ts-ignore
  let forErisDateTime: string = mode === 'transits'
    ? progDt
    : mode === 'directions'
      ? localTime(form.natal)
      // @ts-ignore
      : ext.dt;

  let ErisObjectPrognostics = await astro.object(forErisDateTime, 136199 + 10000);

  if (mode === 'directions') {
    // @ts-ignore
    ErisObjectPrognostics = directionShift(ErisObjectPrognostics, ext.shift);
  }

  const [prognosticsExt, prognosticsNatal] = [
    (mapIndicator as any || {}).prognosticsExt?.[mode as PrognosticsMode],
    (mapIndicator as any || {}).prognosticsNatal?.[mode as PrognosticsMode]
  ];

  const res = (prognosticsExt && prognosticsNatal)
    ? {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects: astro.prognosticsAspects(
        mode,
        natal.objects,
        natal.houses,
        ext.objects,
        ext.houses,
        false // forDoubleMapWithOutNatal
      ),
      objectsExt: [...ext.objects, ErisObjectPrognostics],
      housesExt: ext.houses,
      objectsOuter: [],
      housesOuter: []
    }
    : (prognosticsExt && !prognosticsNatal)
      ? {
        ...ext,
        objects: [...ext.objects, ErisObjectPrognostics],
        aspects: astro.prognosticsAspects(
          mode,
          natal.objects,
          natal.houses,
          ext.objects,
          ext.houses,
          Boolean('forDoubleMapWithOutNatal') //true
        ),
        objectsExt: [],
        housesExt: [],
        objectsOuter: [],
        housesOuter: []
      }
      : {
        ...natal,
        objects: [...natal.objects, ErisObjectNatal],
        aspects: astro.natalAspects(natal.objects),
        objectsExt: [],
        housesExt: [],
        objectsOuter: [],
        housesOuter: []
      }
    ;

  return (
    res
  );

}

