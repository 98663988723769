import { CloseIcon } from "src/assets/icons/system"
import { ReactComponent as LogoSvg} from "src/assets/illustrations/topbar-indi-logo.svg"
import { ReactComponent as Logo2Svg} from "src/assets/illustrations/topbar-indi-logo-2.svg"
import styled from "styled-components"
import banner from './banner.png';
import { observer } from "mobx-react";
import store from 'src/store';
import { useEffect } from "react";
import { googleEvent } from "src/utils";
import { isTester } from "src/helpers/autotests";

export default observer(function TopBar() {
    const { user: userStore } = store.settings;
    const { indiBanner } = store.settings.banners;
    const email = store.settings.user.profile.email ?? '';

    const closeBanner = () => store.settings.banners.closeIndiBanner();

    const viewMainBanner = () => {
        store.settings.banners.viewMainIndiBanner();
    }

    useEffect(() => {
        if(indiBanner === 'main') {
            googleEvent('app_popup-indi_shown', userStore.auth.id);
        }
    }, [indiBanner])

    const goToLinkFromMainBanner = () => {
        closeBanner();
        googleEvent('app_popup-indi_click', userStore.auth.id)
        location.href = 'https://indi.chronos.mg/trial?utm_source=app&utm_medium=popup';
    }

    const goToLinkFromTopBarBanner = () => {
        closeBanner();
        googleEvent('app_topbar-indi_click', userStore.auth.id)
        location.href = 'https://indi.chronos.mg/trial?utm_source=app&utm_medium=topbar';
    }

    if (isTester(email)) return null

    return <>
        {indiBanner === 'topbar' && <Container>
            <Center>
                <Logo><LogoSvg /></Logo>
                <Text>Персональный ежедневный прогноз по 7 сферам жизни</Text>
                <Button onClick={goToLinkFromTopBarBanner}>Попробовать 7 дней бесплатно</Button>
            </Center>
            <Right onClick={closeBanner}><CloseIcon /></Right>
        </Container>}

        {indiBanner === 'main' && <Overlay>
            <Popup>
                <PopupHeader>
                    <PopupHeaderLeft><Logo2Svg/></PopupHeaderLeft>
                    <PopupHeaderRight onClick={viewMainBanner}><CloseIcon /></PopupHeaderRight>
                </PopupHeader>
                <Content>
                    <Img src={banner} />
                    <Title>Персональный ежедневный прогноз по 7 сферам жизни</Title>
                    <Description>Постройте свой прогноз на основе вашей даты, времени и месте рождения</Description>
                    <Button2 onClick={goToLinkFromMainBanner}>Попробовать 7 дней бесплатно</Button2>
                </Content>
            </Popup>
        </Overlay> }
    </>

})

const Button2 = styled.div`
    color: white;
    background: #4760E7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem 0.6rem;
    margin: 1.5rem 0 3rem;
    cursor: pointer;

    :hover {
        opacity: 0.75;
    }
`

const Content = styled.div`
    padding: 0 3.1875rem;
`

const Title = styled.div`
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--text-primary);
    margin-bottom: 0.75rem;
`

const Description = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-secondary);
`

const Img = styled.img`
    width: 100%;
    margin: 2.25rem 0;
`

const PopupHeader = styled.div`
    display: flex;
    align-items: center;
`

const PopupHeaderLeft = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;

    svg {
        height: 1.25rem;
    }
`

const PopupHeaderRight = styled.div`
    svg {
        height: 1rem;
        width: 1rem;
        path {
            fill: var(--text-primary);
        }
    }
    cursor: pointer;

    :hover {
        opacity: 0.75;
    }
`

const Popup = styled.div`
    background: var(--bg-600);
    border-radius: 0.675rem;
    padding: 2rem;
    width: 30rem;
    text-align: center;
    font-size: 1rem;
`


const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: #1D1D1Dcc;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`




const Container = styled.div`
    display: flex;
    position: relative;
    box-sizing: border-box;
    padding: 0.75rem;


    /* min-height: 2.625rem; */
    background-color: #4760E7;
    color: white;
    align-items: center;
    width: 100%;
    font-size: 1rem;
`

const Center = styled.div`
    display: flex;
    gap: 1.5rem;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const Right = styled.div`
    /* padding: 0 0.75rem; */
    cursor: pointer;

    :hover {
        opacity: 0.75;
    }

    svg {
        width: 1rem;
        height: 1rem;
        /* path {
            fill: white;
        } */
    }
`

const Logo = styled.div`
    svg {
        height: 1.25rem;
        display: block;
    }
`

const Text = styled.div`
`

const Button = styled.div`
    background: white;
    border-radius: 0.25rem;
    padding: 0.125rem 0.75rem;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
    //padding-bottom: 0.2rem;

    :hover {
        opacity: 0.75;
    }
`
