import React from 'react';
import styled, { css } from 'styled-components';

import api, { IFormData, toDateTimeString } from 'src/api';
import { clickOutsideHook, ReactMemo } from 'src/hooks';

import { Header } from 'src/components/EditForm';

import CloseButton from 'src/ui/CloseButton';
import { Input, Button, Switcher, show, ToPrintButton } from 'src/libs';

import { 
  ImportIcon,
  ShevronIcon,
  GlobalIcon,
  PrivateIcon,
  DeniedIcon,
  OkIcon,
} from 'src/assets/icons/system';

import { PrintIcon } from 'src/assets/icons/system/24';

import { 
  TelegramIcon,
  WhatsAppIcon,
  VkontakteIcon,
  FacebookIcon,
  TwitterIcon
} from 'src/assets/icons/social';
import { useTranslation } from 'src/i18n/useTranslation';
import { formGenLS, IGen } from 'src/helpers/localStorage';

const PrivateIconColor = styled(DeniedIcon)`color: #EF5350;`;
const PublicIconColor = styled(GlobalIcon)`color: #73B330;`;
const ProtectedIconColor = styled(PrivateIcon)`color: #F59300;`;

interface IAccessType {
  id: string;
  icon: React.ReactNode;
  title: string;
  description: string;
}

const accessTypes: IAccessType[] = [
  { id: 'public', icon: <PublicIconColor />, title: "chronos.app.components.shareForm.public.title", description: "chronos.app.components.shareForm.public.description" },
  { id: 'protected', icon: <ProtectedIconColor />, title: "chronos.app.components.shareForm.protected.title", description: "chronos.app.components.shareForm.protected.description" },
  { id: 'private', icon: <PrivateIconColor />, title: "chronos.app.components.shareForm.private.title", description: "chronos.app.components.shareForm.private.description" }
];

export default ReactMemo(function ShareForm(props: {
  form: IFormData;
  onClose(): void;
  center?: boolean;
  onUpdate(form: IFormData): void;
  onBeforePrint?(): void;
}) {
  const [accessWindowShow, setAccesWindowShow] = React.useState(false);
  const [accessType, setAccessType] = React.useState(accessTypes.find(t => t.id == props.form.access.type)!);
  const [showPersonalData, setShowPersonalData] = React.useState(props.form.access.showPersonalData);
  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);
  const { t } = useTranslation();
  const thisRef = React.useRef<HTMLDivElement>(null);

  clickOutsideHook(thisRef, props.onClose);

  const accessRef = React.useRef<HTMLDivElement>(null);

  clickOutsideHook(accessRef, () => setAccesWindowShow(false));

  const link = `${process.env.REACT_APP_BACKEND}/api/share/${props.form.id}?t=${Date.now()}`;

  const toggleAccessWindow = () => setAccesWindowShow(!accessWindowShow);

  const update = (access: IAccessType, showPersonalData: boolean) => {
    const form = {
      ...props.form,
      access: {
        ...props.form.access,
        type: access.id,
        showPersonalData
      }
    };

    // цепляем gen для формы из ls на всякий случай
    form.gen = formGenLS.get(form.id, form.gen) as IGen;

    api.updateForm(form);
    props.onUpdate(form);
  };

  const changeAccessType = (type: IAccessType) => {
    setAccessType(type);
    setAccesWindowShow(false);
    update(type, showPersonalData);
  };

  const changeShowDateTime = (showPersonalData: boolean) => {
    setShowPersonalData(showPersonalData);
    update(accessType, showPersonalData);
  };

  const copyToClipboard = () => {
    navigator.clipboard?.writeText(link)
      .then(() => {
        show({
          type: 'success',
          closable: true,
          timeout: 3000,
          text: t("chronos.app.components.shareForm.copyToClipboard.success")
        });
        setCopiedToClipboard(true);
        setTimeout(() => setCopiedToClipboard(false), 3000);
      })
      .catch(err => {
        show({
          type: 'error',
          closable: true,
          timeout: 3000,
          text: t("chronos.app.settings.profileSteps.noAccessToClipboard")
        });
      });
  };


  const socURL = encodeURIComponent(link);
  const socText = encodeURIComponent(`${props.form.name}${props.form.access.showPersonalData ? `, ${toDateTimeString(props.form.natal.dt)}, ${props.form.natal.place.name}` : ''}`);

  return <Container
    ref={thisRef}
    center={props.center ?? false}
  >
    <ShareHeader>
      <span>
        <ImportIcon />
        <span>{t("base.share")}</span>
      </span>
      <CloseButtonNew onClick={props.onClose} />
    </ShareHeader>

    <ChooseAccess onClick={toggleAccessWindow}>
      <GlobalIconContainer>
        {accessType.icon}
      </GlobalIconContainer>
      <ChooseAcessTitle>
        <Title>{t(accessType.title)}</Title>
        <SubTitle>{t(accessType.description)}</SubTitle>
      </ChooseAcessTitle>
      <ShevronIconContainer opened={accessWindowShow}>
        <ShevronIcon />
      </ShevronIconContainer>
    </ChooseAccess>

    {accessWindowShow && <ChooseAccessHiddenBlock ref={accessRef}>
      {accessTypes.map(item =>
        <ChooseAccessBlock
          key={item.id}
          onClick={() => changeAccessType(item)}
        >
          {item.icon}
          <div>
            <Title>{t(item.title)}</Title>
            <SubTitle>{t(item.description)}</SubTitle>
          </div>
        </ChooseAccessBlock>
      )}
    </ChooseAccessHiddenBlock>}

    {accessType.id !== 'private' && <>
      <CircleButtons>
        <CircleButton color="#2AABEE" target="_blank" href={`https://t.me/share/url?url=${socURL}&text=${socText}`}><TelegramIcon /></CircleButton>
        <CircleButton color="#25D366" target="_blank" href={`https://wa.me/?text=${socURL + ' ' + socText}`}><WhatsAppIcon /></CircleButton>
        <CircleButton color="#4680C2" target="_blank" href={`https://vk.com/share.php?url=${socURL}&title=${socText}&noparse=true`}><VkontakteIcon /></CircleButton>
        <CircleButton color="#1877F2" target="_blank" href={`https://www.fb.com/sharer/sharer.php?p[url]=${socURL}&p[title]=${socText}`}><FacebookIcon /></CircleButton>
        <CircleButton color="#1DA1F2" target="_blank" href={`https://twitter.com/share?url=${socURL}&text=${socText}`}><TwitterIcon /></CircleButton>
      </CircleButtons>

      <LinkBlock copied={copiedToClipboard}>
        <Input onChange={() => { }} value={link} />
        {!copiedToClipboard && <Button onClick={copyToClipboard} color="transparentWithBorder">{t("chronos.app.components.shareForm.copy")}</Button>}
        {copiedToClipboard && <Button onClick={copyToClipboard} color="transparentWithBorder"><OkIconSized /></Button>}
      </LinkBlock>

      <DateTimeContainer>
        <span>{t("chronos.app.components.shareForm.showDateTimePlace")}</span>
        <Switcher checked={showPersonalData} onChange={changeShowDateTime} />
      </DateTimeContainer>
    </>}

      <StyledToPDFButton
          size='small'
          color='transparent'
          elements={['.place-for-print', '.spacing', '.map-print-wrapper']}
          excludes={['.close-button', 'button']}
          inseparable={['.print-inseparable']}
        >
    <DownloadContainer>
      <Download>
          <PrintIcon />
          <div>
              <TitlePrint>{t("chronos.app.components.shareForm.printMap")}</TitlePrint>
              <SubTitle>{t("chronos.app.components.shareForm.printMap.ifThereIsAPrinter")}</SubTitle>
          </div>
      </Download>
    </DownloadContainer>
        </StyledToPDFButton>
  </Container>;
});


const DownloadContainer = styled.div`
`;

const Download = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;
    grid-column-gap: 1.125rem;

    & > svg {
        width: 1.5rem;
        color: var(--icon-secondary);
    }

    &:hover::before {
        cursor: pointer;
        content: '';
        position: absolute;
        width: calc(100% + 1.5rem);
        height: calc(100% + 1.7rem);
        top: -0.75rem;
        left: -0.75rem;
        background-color: var(--bg-share-hover);
        border: 1px solid var(--bg-share-hover);
        border-radius: 4px;
        box-sizing: border-box;
    }
`;

const DateTimeContainer = styled.div`
    margin-bottom: 1.375rem;
    font-size: 0.875rem;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LinkBlock = styled.div<{ copied: boolean }>`
    display: grid;
    grid-template-columns: 1fr 7.5rem;
    grid-gap: 0.675rem;
    margin-bottom: 1.125rem;

    & > input {
        padding: 0.5rem 0.375rem 0.55rem 0.375rem;
    }

    & > button {
        font-size: 0.875rem;
        padding: 0.5rem 0 0.55rem 0;

        ${props => props.copied && css`
            color: var(--colors-green);
            border-color: var(--colors-green);
            background: rgba(115, 178, 48, 0.08);
            padding: 0.55rem 0 0.5rem 0;
        `}
    }
`;

const OkIconSized = styled(OkIcon)`
    height: 0.875rem;
    width: auto;
`;

const ChooseAccessHiddenBlock = styled.div`
    position: absolute;
    width: calc(100% - 2.5rem);
    background-color: var(--bg-share);
    box-shadow: 0px 0.6px 1.8px 0px #000000 11%;
    box-shadow: 0px 3.2px 7.2px 0px #000000 13%;
    border-radius: 6px;
    z-index: 100;
    box-sizing: border-box;
    padding: 1.36rem 1.125rem 1.56rem 1.125rem;
    display: grid;
    grid-row-gap: 2.25rem;
`;

const ChooseAccessBlock = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;
    grid-column-gap: 1.125rem;

    & > svg {
        width: 1.5rem;
    }

    &:hover::before {
        cursor: pointer;
        content: '';
        position: absolute;
        width: calc(100% + 1.5rem);
        height: calc(100% + 1.7rem);
        top: -0.75rem;
        left: -0.75rem;
        background-color: var(--bg-share-hover);
        border: 1px solid var(--bg-share-hover);
        border-radius: 4px;
        box-sizing: border-box;
    }
`;

const ChooseAcessTitle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 1rem;
    color: var(--text-primary);
    margin-bottom: 0.325rem;
`;

const TitlePrint = styled.div`
    font-size: 1rem;
    color: var(--text-primary);
    padding-bottom: 1rem;
`;

const SubTitle = styled.div`
    font-size: 0.75rem;
    color: var(--text-secondary);
`;

const ShareHeader = styled(Header)`
  display:flex;
  justify-content: space-between;
  margin-bottom: 1.375rem;
  padding-right: 0;

  & span {
      display: flex;
      align-items: center;

      color: var(--text-primary);
      white-space: nowrap;

      &:first-child svg {
        width: 1.5em;
        margin-right: 0.5em;
        fill: var(--icon-secondary);
      }
    }
`;

const CloseButtonNew = styled(CloseButton)`
    svg {
        padding: 0.375rem;
        width: 1.125rem !important;
    }
`;

const ChooseAccess = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1.125rem;
    margin-bottom: 1.375rem;
    
    &:hover div{
        color: var(--text-primary) !important;
    }
`;

const GlobalIconContainer = styled.div`
    line-height: 0px;
    & > svg {
        width: 1.5rem;
        background-color: var(--bg-share-hover);
        padding: 0.56rem;
        border: 1px solid var(--bg-share-hover);
        border-radius: 6px;
    }
`;

const ShevronIconContainer = styled.div<{ opened: boolean }>`
    display: flex;
    align-items: center;
    & > svg {
        width: 1.5rem;
        color: var(--icon-secondary);
        transition: transform 0.2s;
        transform: rotate(${props => props.opened ? '180' : '0'}deg);
    }
`;

const Container = styled.div<{ center: boolean }>`
    ${props => !props.center && css`
        position: absolute;
        z-index: 2;
        top: calc(100% + 0.5em);
        right: 0;
    `}

    font-size: 1rem;
    width: 28rem;
    box-sizing: border-box;
    padding: 1.25rem 1.25rem 0.625rem 1.25rem;
    border-radius: 6px;
    background: var(--workspace-widget-background);
    color: var(--text-secondary);
    box-shadow: var(--workspace-box-shadow);
`;

const CircleButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`;

const CircleButton = styled.a<{ color: string }>`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background: ${props => props.color};
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;

    & > svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--colors-white);
    }

    &:hover {
        opacity: 0.6;
    }
`;

const StyledToPDFButton = styled(ToPrintButton)`
  width: 100%;
  padding-top: 1.4375rem;
  padding-bottom: 0.3rem;
  border-top: 1px solid var(--element-neutral);
  text-align: left;
  align-items: center;
  position: relative;

 & > svg {
      display: none;
    }
`;