import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import {
  degToString,
  SessionStatus,
  SessionFormat,
  ISession,
  IAuth,
  IUser,
  nowISOString,
  IFixedGmt,
  IPlaceEx,
  fromDateTime, getGMT, IPlace,
  toDateTime, IChain
} from 'src/libs';

import { t } from 'i18next';
import store from './store';
import { history } from './router';

export const months = [
  "base.months.january.nom",
  "base.months.february.nom",
  "base.months.march.nom",
  "base.months.april.nom",
  "base.months.may.nom",
  "base.months.june.nom",
  "base.months.july.nom",
  "base.months.august.nom",
  "base.months.september.nom",
  "base.months.october.nom",
  "base.months.november.nom",
  "base.months.december.nom"
];

export const months2 = [
  "base.months.january.gen",
  "base.months.february.gen",
  "base.months.march.gen",
  "base.months.april.gen",
  "base.months.may.gen",
  "base.months.june.gen",
  "base.months.july.gen",
  "base.months.august.gen",
  "base.months.september.gen",
  "base.months.october.gen",
  "base.months.november.gen",
  "base.months.december.gen"
];

export const parseSize = (bytes: number, si=false, dp=1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
};


export function loadImage(url: string): Promise<unknown> {
  const image = new Image();
  const promise = new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = () => reject(Error(`Loading error - ${image.src}`));
  });
  image.src = url;
  return promise;
}

export function getURL() {
  return new URL(document.location.href)
}

export function searchParams(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function clearURLSearchParams(keys: string | string[], method?: 'push' | 'replace'): void {
  if (!keys || !window.location.search) return;
  
  const methodName = method || 'push';
  let { pathname, search } = window.location
  let href: string;
  
  keys = Array.isArray(keys) ? keys : [keys];
  const sp = new URLSearchParams(search)
  keys.map(key => sp.delete(key))
  const searchStr = sp.toString()
  href = `${pathname}${searchStr.length ? `?${searchStr}` : ``}`
  history[methodName](href)
}

export function readFile<T extends string | ArrayBuffer>(f: File, isBinary = false) {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader;

    reader.onload = e => resolve(e.target?.result as T);
    reader.onerror = e => reject(e.target?.error);

    if (isBinary) reader.readAsArrayBuffer(f);
    else reader.readAsText(f, "CP1251");
  });
}

// описание объекта options https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

export function dateFormat(date: any, options?: {[key:string]: string}): string {
  return new Intl.DateTimeFormat(localStorage.getItem('i18nextLng') ?? 'ru-RU' , options).format(new Date(date));
}

export function nowLocalISOString(): string {
  const nowDate = new Date();
  const currentTimeZoneOffset = nowDate.getTimezoneOffset();
  nowDate.setMinutes(nowDate.getMinutes() - currentTimeZoneOffset);
  return nowDate.toISOString();
}

export function daysInMonth(month: number, year: number): number {
  if (month === 2) {
    return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0 ? 29 : 28;
  }

  return 30 + [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1][month - 1];
}

export function formatMessage(n: number, text_forms: string[], t: Function): string {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) { return t(text_forms[2]) }
  if (n1 > 1 && n1 < 5) { return t(text_forms[1]) }
  if (n1 === 1) { return t(text_forms[0]) }
  return t(text_forms[2]);
}

export function getOrbit(chains: IChain[], planet: number): number {
  return chains.find(chain => !!chain.objects[planet])?.objects[planet].orbit || 0;
}

export function roundTo(num: number, precision: number): number {
  const div = 10 ** precision;
  return Math.round((num + Number.EPSILON) * div) / div;
}

export function checkGenChanged(gen: {
  autoprediction: boolean;
  devplan: boolean;
  rectification: boolean;
}, dt1: string, dt2: string, pl1: IPlace, pl2: IPlace): boolean {

  const { subscriptions } = store.settings.user.auth;
  const isProfTariff = subscriptions?.length && subscriptions[subscriptions.length - 1] === 4;

  return (gen.autoprediction || gen.devplan || gen.rectification) && !isProfTariff &&
  (
    toDateTime(dt1).date !== toDateTime(dt2).date ||
    degToString(pl1.lat, { isLatitude: true }) !== degToString(pl2.lat, { isLatitude: true }) ||
    degToString(pl1.lon) !== degToString(pl2.lon)
  );
}

export function attachMouseWheelHandler(elem: HTMLElement, onWheelCb: () => void): string {
  if(!elem) return '';

  let eventName = '';

  if (elem?.addEventListener) {
    if ('onwheel' in document) {
      eventName = "wheel";
      elem.addEventListener(eventName, onWheelCb);
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      eventName = "mousewheel";
      elem.addEventListener(eventName, onWheelCb);
    } else {
      // Firefox < 17
      eventName = "MozMousePixelScroll";
      elem.addEventListener(eventName, onWheelCb);
    }
  }

  return eventName;
}

export const getGMTToString = (gmt: number): string => {
  const hours = Math.floor(gmt).toString().padStart(2, '0');
  const minutes = Math.floor((gmt % 1) * 60).toString().padStart(2, '0');
  const seconds = '00';

  return `${hours}:${minutes}:${seconds}`;
}

export const copyToClipboard = (data: string): Promise<any> => {
  return navigator.clipboard?.writeText(data);
};

export const hexToRGBA = (color: string, alfa: number): string => {
  const hex: number = Number(`0x${color.replace(/#/, '')}`);
  const r = hex >> 16 & 0xFF;
  const g = hex >> 8 & 0xFF;
  const b = hex & 0xFF;
  return `rgba(${r}, ${g}, ${b}, ${alfa})`;
}

export const convertPixelsToRem = (pxSize: number): string => {
  const rootSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return `${(pxSize / rootSize).toFixed(2)}rem`;
}

export function convertToOffset(time: string, timeOffset: number, from?: boolean): string {
  const hoursRaw = +time.split(':')[0];
  const minutesRaw = +time.split(':')[1];
  let result = hoursRaw * 60 + minutesRaw + (from ? -timeOffset : timeOffset);

  result = result >= 1440 ? result - 1440 : result;
  result = result < 0 ? result + 1440 : result;

  let hours = Math.floor(result / 60).toFixed(0);
  hours = Number(hours) <= 9 ? `0${hours}` : `${hours}`;

  let minutes = Math.floor(result % 60).toFixed(0);
  minutes = Number(minutes) <= 9 ? `0${minutes}` : `${minutes}`;

  return `${hours}:${minutes}`;
}


export const getDiscountByCreator = (creator: string, service: any) => {
  switch (creator) {
    case 'app':
      return 0;
    case 'direct':
      return service.linkReward;
    default:
      return service.reward || service.linkReward;
  }
}

export const getPriceValue = (
  { value = 0, discount = 0, currency = 'RUB', rates }: { value?: number; discount?: number; currency?: string; rates: {[key: string]: number} }
): number => {
  const currencyCode = currency.toLowerCase();
  const val = currencyCode === 'usd' ? Math.trunc(value / rates[currencyCode]) : value;
  return discount === 0 ? val : Math.floor(val * discount)
}

export const addGMTToISOString = (isoDateString: string): string =>
  dayjs(isoDateString).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z';

export const subtractGMTToISOString = (isoDateString: string): string =>
  dayjs(isoDateString.replace('Z', '')).toISOString()

const statusTexts: {
  [key: string]: {
    text: {[key: string] : string};
    shortText?: string;
    style?: any;
    cellColor?: string;
  }
} = {
  [SessionStatus.Init]: {
    text: {
      [SessionFormat.Any]: "chronos.app.consultationNotPaid",
    },
  },
  [SessionStatus.Paid]: {
    text: {
      [SessionFormat.Online]: "chronos.app.beforeConsultation",
      [SessionFormat.Offline]: "chronos.app.utils.toLoadTheResult",
    },
  },
  [SessionStatus.Canceled]: {
    text: {
      [SessionFormat.Any]: "chronos.app.utils.consultationCanceled",
    },
    style: {
      color: '#EF5350',
      cellTextColor: 'rgba(239, 83, 80, 0.6)',
      cellTextColor2: 'rgba(239, 83, 80, 0.6)',
      borderColor: 'rgba(239, 83, 80, 0.37)',
      bgColor: 'rgba(239, 83, 80, 0.05) !important',
    },
  },
  [SessionStatus.InProgress]: {
    text: {
      [SessionFormat.Any]: "chronos.app.utils.consultationInProgress",
    },
    style: { color: '#ED682E' },
    shortText: "chronos.app.utils.inProgress",
  },
  [SessionStatus.Finished]: {
    text: {
      [SessionFormat.Any]: "chronos.app.utils.consultationHeld",
    },
    style: { color: '#73B230' },
  },
  [SessionStatus.PaidOut]: { text: {} },
  [SessionStatus.Litigation]: { text: {} },
  [SessionStatus.Rollbacked]: { text: {} },
  [SessionStatus.PartiallyPaid]: {
    text: {
      [SessionFormat.Any]: "chronos.app.utils.consultationPartiallyPaid",
    },
  },
  missed: {
    text: {
      [SessionFormat.Any]: "chronos.app.utils.consultationIsOverdue",
    },
    style: {
      color: '#ED682E',
      cellTextColor: '#ED682E',
      cellTextColor2: 'rgba(237, 104, 46, 0.6)',
      borderColor: 'rgba(237, 104, 46, 0.37)',
      bgColor: 'rgba(237, 104, 46, 0.05) !important',
    },
    shortText: "chronos.app.utils.overdue",
  },
};

export const getSessionStatus = (session: ISession) => {
  let { start, data: {duration, status, meeting, format} } = session;

  const statusObj: {
    text: string;
    style: { color?: string};
    shortText?: string;
  } = { text: '', style: {}};

  if (format === SessionFormat.Any) {
    return statusObj;
  }

  const s = dayjs(start);
  const e = dayjs(start).add(duration, 'minutes');

  if (start && dayjs().isBetween(s, e)) {
    if (format === SessionFormat.Online ) {
      status = SessionStatus.InProgress;
    }
  }

  statusObj.style = statusTexts[status]?.style || {};
  statusObj.shortText = statusTexts[status]?.shortText || '';
  statusObj.text = statusTexts[status]?.text[session.data.format] || statusTexts[status]?.text[SessionFormat.Any] || '';

  if(start && dayjs().isAfter(e)) {
    const isMissedOfflineSession = session.data?.documents?.length === 0 || dayjs(session.data?.documents?.[0].modified).isAfter(e)

    if(
      ([SessionStatus.Finished, SessionStatus.Paid, SessionStatus.PaidOut].includes(status)) &&
      (
        (format === SessionFormat.Online && !meeting?.recordingUrls?.urls?.length) ||
        (format === SessionFormat.Offline && isMissedOfflineSession)
      )
    ) {
      statusObj.text = t(statusTexts.missed.text[SessionFormat.Any]);
      statusObj.style = statusTexts.missed.style;
      statusObj.shortText = statusTexts.missed.shortText;
    } else if (
      status === SessionStatus.Paid &&
      format === SessionFormat.Online &&
      meeting?.recordingUrls?.urls?.length
    ) {
      status = SessionStatus.Finished;
      statusObj.style = statusTexts[status]?.style || {};
      statusObj.shortText = statusTexts[status]?.shortText || '';
      statusObj.text = statusTexts[status]?.text[session.data.format] || statusTexts[status]?.text[SessionFormat.Any] || '';
    } else if (
      status === SessionStatus.Paid &&
      format === SessionFormat.Offline &&
      !isMissedOfflineSession
    ) {
      statusObj.text = t(statusTexts[SessionStatus.Finished].text[SessionFormat.Any]);
    }
  }

  // before start
  if(start && dayjs().isBefore(s) && status === SessionStatus.Paid) {
    if (format === SessionFormat.Online) {
      statusObj.text = `${s.fromNow(true)} ${t(statusObj.text)}`;
    }
  }

  // before end
  if(start && dayjs().isBefore(e) && status === SessionStatus.Paid) {
    if (format === SessionFormat.Offline) {
      statusObj.text = `${e.fromNow(true)} ${t(statusObj.text)}`;
    }
  }

  return statusObj;
}

export const initialAuth: IAuth = {
  id: -1,
  name: '',
  firstName: '',
  // @ts-ignore
  gender: null,
  lastName: '',
  subscriptions: [],
  expiryDate: dayjs().add(1, 'hours').toISOString(),
  autoPayment: false,
  token: {
    refresh: '',
    access: ''
  },
  theme: 'dark',
  permissions: {
    isTrial: false,
    isExpired: false,
    hasBeginnerAccess: false,
    hasArchivedAccess: false,
    hasProfessionalAccess: false,
    hasUnlimitedAccess: false,
    hasProAccess: false,
    hasBaseAccess: false,
    canUseOnline: true,
    canUseOffline: false,
    canUseSynastry: false,
    canUsePrognostics: false,
    canUseHorar: false,
    canUseRectification: false,
    canUseSoulFormulas: false,
  },
  levelOfAstrology: 0,
  avatarUrl: '',
  showWelcome: false,
  showDawnOnboarding: false,
  showWelcomeNewInterface: false,
  showWelcomeInstruments: false,
  confirmations: {
    privacyAndTerms: false,
  },
  notifications: [],
  isUnderConstruction: false,
  sessionId: -1,
  language: '',
  nps2Visited: [],
  region: undefined,
};

export const getUserByAuth = (auth: IAuth): IUser => {
  return {
    firstName: auth.firstName,
    lastName: auth.lastName,
    // @ts-ignore
    avatarUrl: auth.avatarUrl,
    email: '',
    gender: auth.gender,
    levelOfAstrology: auth.levelOfAstrology,
    phoneNumber: '',
    partner: '',
    id: auth.id,
    sessionId: auth.sessionId,
    // @ts-ignore
    subscriptions: auth.subscriptions,
    permissions:  auth.permissions,
    showWelcomeInstruments: auth.showWelcomeInstruments,
    region: auth.region,

    birth: {
      dateTime: nowISOString(),
      gmt: null,
      place: {
        name: '',
        lat: 0,
        lon: 0
      }
    },


  };

}

export const getDeviceDetect = () => {
  const userAgent = typeof window.navigator === 'undefined'
    ? ''
    : navigator.userAgent;

  const isMobile =
    /android|webos|iphone|ipod|blackberry|bb|playbook|iemobile|windows phone|kindle|silk|opera mini|wpdesktop/i
    .test(userAgent);

  // Workaround to detect ipads safari with turned on "request desctop version" (turned on by default)
  const isIPadDesktop = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

  const isTablet = isIPadDesktop ||
    /Tablet|iPad|Nexus 7|Nexus 10|SM-(T|X)\d+|KFAPWI/i
    .test(userAgent);
  const isIPad = isIPadDesktop || (isTablet && /iPad/i.test(userAgent));

  return { isMobile, isTablet, isIPad };
}


const locales: {[key: string]: any} = {
  ru: () => import('dayjs/locale/ru'),
  en: () => import('dayjs/locale/en'),
  pt: () => import('dayjs/locale/pt'),
  es: () => import('dayjs/locale/es'),
}

export const loadLocale = async (lang: string) => (locales[lang] || locales.en)?.();

const debug = false

export const getIntervalWithThisGmt = (startDateTime: string, fixedGmt: number, place: IPlace): Promise<{ dateKey: string, start: string; end: string; gmt: number; place: IPlace }> => {
  enum Direction { FORWARD = 1, BACKWARD = -1 }
  let timer: any;

  const normalizeStartDate = fromDateTime(toDateTime(startDateTime).date, '00:00:00');

  const promise: Promise<{ dateKey: string, start: string, end: string, gmt: number, place: IPlace }> = new Promise((resolve, reject) => {
    const stepInterval = ['year','month', 'day', 'hour'];

    let minDate: string = '';
    let maxDate: string = '';

    const { lat, lon } = place || {};

    const nowDate: Dayjs = dayjs();
    const minAccessDate: Dayjs = dayjs('1200-01-01T00:00:00.000Z');
    let iterableDate: Dayjs = dayjs(normalizeStartDate);
    let startGmt: number = getGMT(startDateTime, lat, lon);

    let dir: Direction = Direction.FORWARD;
    let backwardDir = (0 - dir);

    let interval: number = 0;
    

    debug && console.log(`start check tresholds - startDate: ${normalizeStartDate.toString()} with gmt ${startGmt} `)

    const step = () => {
      const currentStep = stepInterval[interval] as ManipulateType;
      
      iterableDate = iterableDate.add(dir, stepInterval[interval] as ManipulateType);
      const gmt = getGMT(iterableDate.toISOString(), lat, lon);
      
      debug && console.log(`step --- date: ${iterableDate.toISOString()} dir: ${dir} step: ${currentStep} gmt: ${gmt} startGmt:${startGmt} minAccessDate: ${minAccessDate.format("MM-DD-YYYY")}`);

      if (gmt !== startGmt || (dir == Direction.FORWARD && iterableDate >= nowDate) || (dir == Direction.BACKWARD && iterableDate <= minAccessDate)) { // || (dir == Direction.BACKWARD && iterableDate <= minAccessDate)
        interval++;
        iterableDate = iterableDate.add(backwardDir, currentStep);

        if (!stepInterval[interval]) {
          debug && console.log(`out of bounds dir: ${dir} step: ${(stepInterval as any)[interval - 1]} interval: ${interval}`);

          if (dir == Direction.FORWARD) {
            maxDate = iterableDate.utc().minute(59).second(59).millisecond(0).toISOString();
          } else {
            minDate = iterableDate.utc().minute(0).second(0).millisecond(0).toISOString();
          }

          iterableDate = dayjs(startDateTime);
          interval = 0;
          dir = Direction.BACKWARD;
          backwardDir = (0 - dir);

          minDate && maxDate && dir === Direction.BACKWARD
            ? (resolve({
              dateKey: normalizeStartDate,
              start: minDate,
              end: maxDate,
              gmt: fixedGmt,
              place: { ...place }
            }))
            : (timer = setTimeout(step, 0))
        } else {
          timer = setTimeout(step, 0);
        }

      } else {
        timer = setTimeout(step, 0);
      }
      
    }
  
    timer = setTimeout(step, 0);

});
  
  //@ts-ignore
  promise.cancel = () => {
    clearTimeout(timer);
  }
  
  return promise;
}

export const getFixedGmt: any = ({ dt, lat, lon, data }: { dt: string, lat?: number, lon?: number, data?: { [key: string]: IFixedGmt }}): { gmt: number | null; key: string } => {
  const keys = Object.keys(data || {});
  let result: { gmt: number | null; key: string } = { gmt: null, key: '' };
  
  if (!keys.length) return result;

  const normalizeDt = fromDateTime(toDateTime(dt).date, '00:00:00');

  const dataByDateAsKey: IFixedGmt = (data as any)?.[normalizeDt];
  if (dataByDateAsKey && dataByDateAsKey.lat === lat && dataByDateAsKey.lon === lon) {
    result.gmt = dataByDateAsKey.gmt;
    result.key = normalizeDt;
    return result;
  }

  const allFixedKeys: string[] = keys; 
  const allFixedData: IFixedGmt[] = Object.values(data!);

  for (let i = 0; i < allFixedData.length; i++) {
    const {range, gmt, lat: fixedLat, lon: fixedLon } = allFixedData[i];
    const currentDt = dayjs.utc(normalizeDt);
    const rangeStart = dayjs.utc(range[0]);
    const rangeEnd = dayjs.utc(range[1]);
    if ((rangeStart <= currentDt) && (currentDt <= rangeEnd) && (fixedLat === lat && fixedLon === lon)) {
      result.gmt = gmt;
      result.key = allFixedKeys[i];
      break;
    }
  }
  return result;
}

export const isCustomPlace = (place: IPlace | null): boolean => {
  if (!place) return true;
  
  const { name, originalName, lat, lon, originalLat, originalLon } = place as IPlaceEx;
  return Boolean((typeof originalName === 'string' && name !== originalName) 
    || (typeof originalLat === 'number' && lat !== originalLat) 
    || (typeof originalLon === 'number' && lon !== originalLon));
}

export const isCustomPlaceName = (place: IPlace): boolean => {
  if (!place) return true;
  const { name, originalName } = place as IPlaceEx;
  return Boolean((typeof originalName === 'string' && name !== originalName) || (name && !originalName));
}

export const isCustomPlaceCoordinates = (place: IPlace): boolean => {
  if (!place) return true;
  const { lat, lon, originalLat, originalLon } = place as IPlaceEx;
  return Boolean(
    (typeof originalLat === 'number' && lat !== originalLat) 
    || (typeof originalLon === 'number' && lon !== originalLon)
    || (lat && lon && !originalLat && !originalLon)
  );
}

export function googleEvent(event: string, id: number) {
  try {
    //@ts-ignore
      window.dataLayer.push({
        'event': event,
        'user_id': id
      });
  } catch (error) {
    console.error('google event error', event, error)
  }
}