import { makeAutoObservable } from 'mobx';

import User from './User';
import Payments from './Payments';
import Personalization from './Personalization';
import Profile from './Profile';
import SideBar from './SideBar';
import api from 'src/api';
import BannersStore from './BannersStore';

export default class SettingsStore {
  user = new User();
  profile = new Profile();
  payments = new Payments();
  sidebar = new SideBar();
  personalization = new Personalization();
  banners = new BannersStore();

  constructor() {
    makeAutoObservable(this);
  }

  async init(userId: number): Promise<void> {
    let { activeCustomizationId } = await this.profile.init(userId);

    if (this.user.auth.id != -1) {
      const p = await api.profileSettings(this.user.auth.id);
      activeCustomizationId = p.activeCustomizationId ?? 2;
    }

    await this.personalization.init(
      this.user.auth.id == -1 ? userId : this.user.auth.id,
      activeCustomizationId
    );

    if (this.user.auth.id !== -1) {
      await this.user.getUserProfile();
    }

    if (!this.payments.tariffDetails.length) {
      this.payments.init();
    }
  }
}
