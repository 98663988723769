import React, { useCallback } from 'react';
import styled from 'styled-components';

import api, { toDateTimeString } from 'src/api';
import { ReactMemo } from 'src/hooks';

import { Loader, Input, Checkbox, ToPrintButton, IGenInfo } from 'src/libs';

import { IWidgetData } from './../Widget';
import GenDialog from './../GenDialog';
import LocalTrends, { ILocalTrends } from './LocalTrends';
import GlobalTrends, { IGlobalTrends } from './GlobalTrends';
import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';
import { getDeviceDetect } from 'src/utils';

import { PrintedHeader, PrintedTitle, PrintedFormData, Prolog } from '../utils';
import { ChronosIcon, ProcessorIcon } from 'src/assets/icons/system/index';
import { InfoIcon } from 'src/assets/icons/notifications';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const { isMobile } = getDeviceDetect();

type TrendTypes = 'localTrends' | 'globalTrends';

const debouncedPrediction = debounce(api.autoprediction, 1000).bind(api);

export default ReactMemo(function AutoPrediction(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [info, setInfo] = React.useState<IGenInfo | null>(null);
  const [data, setData] = React.useState<{
    globalTrends: IGlobalTrends;
    localTrends: ILocalTrends;
  } | null>(null);
  const [serviceLife, setServiceLife] = React.useState<string | null>(null)
  const [trendType, setTrendType] = React.useState<TrendTypes>('localTrends');
  const [showReasons, setShowReasons] = React.useState(false);
  const [showAffects, setShowAffects] = React.useState(false);
  const { t } = useTranslation();

  const options = [
    { value: 'localTrends', label: t("chronos.ap.local.heading") },
    { value: 'globalTrends', label: t("chronos.app.instruments.globalTrends") }
  ];



  const onBuild = async (confirmed = true) => {
    const resp = await api.autoprediction(confirmed, props.data.form, i18n.language);

    props.onChanged('autoprediction', resp.done);

    if (resp.done && resp.data) {
      setInfo(null);
      setData(resp.data as any);
      setServiceLife(dayjs(resp.created).add(1, 'year').format('DD.MM.YYYY'))
    } else {
      // Force update
      setInfo({
        ...resp as IGenInfo,
        timestamp: Date.now()
      });

      setData(null);
      setServiceLife(null)
    }
  };


  React.useEffect(() => {
    onBuild(false);
  }, []);

  const checkAutoPrediction = async () => {
    const resp = await api.autoprediction(false, props.data.form, i18n.language);
    if (!resp) return

    if (resp.done) return;

    setInfo({
      ...resp as IGenInfo,
      timestamp: Date.now()
    });
    setData(null);
    setServiceLife(null)    
  };


  React.useEffect(() => {
    checkAutoPrediction()
  }, [props.data.form.id, props.data.form.natal.dt]);

  if (info) {
    return (
      <GenDialog
        title={t("chronos.app.widgets.title.autoPrediction")}
        info={info}
        onBuild={onBuild}
      />
    );
  }

  if (!data) return <LoaderContainer><Loader /></LoaderContainer>;

  // RU
  return (
    <>
      {serviceLife && <Validity>
        <IconContainer>
          <InfoIcon/>
        </IconContainer>
        <div className={'text'}>
          <div className={'title'}>{t("chronos.app.widgets.autoprediction.valid")}<span>{serviceLife}</span></div>
          <div>По истечении срока Автопрогноз будет недоступен. Для получения Автопрогноза на следующий год потребуется оплата.</div>
        </div>
      </Validity>}

      <div id="print-header">
        <PrintedHeader>
          <div>
            <ChronosIcon />
            <ProcessorIcon />
          </div>
          <div className="label">{t("chronos.app.widgets.title.autoPrediction")}</div>
        </PrintedHeader>
        <PrintedTitle>
          {options.find(({ value }) => value === trendType)?.label}
        </PrintedTitle>
        <PrintedFormData>
          <span>{props.data.form.name}</span>,&nbsp;
          <span>{toDateTimeString(props.data.form.natal.dt)}</span>,&nbsp;
          <span>{props.data.form.natal.place.name}</span>
        </PrintedFormData>
      </div>
      <Header>
        <SelectContainer>
          <Input
            options={options}
            value={trendType}
            onChange={v => setTrendType(v)}
            detectMobile={() => isMobile}
          />
          <StyledToPDFButton
            size='small'
            color='transparent'
            elements={['#print-header', '.map-print-wrapper', '#print-content']}
            excludes={['.close-button', 'button', '.check-boxes']}
          >
            {t("chronos.app.downloadInPdf")}
          </StyledToPDFButton>
        </SelectContainer>

        {trendType === 'localTrends' &&
          <CheckBoxes className='check-boxes'>
            <Checkbox
              label={t("chronos.app.instruments.widgets.autoPrediction.causes")}
              checked={showReasons}
              onChange={setShowReasons}
            />
            <Checkbox
              label={t("chronos.app.instruments.widgets.autoPrediction.consequences")}
              checked={showAffects}
              onChange={setShowAffects}
            />
          </CheckBoxes>
        }
      </Header>

      <div id="print-content">
        <Prolog>
          <InfoIcon />
          <div className="text">
            {t("chronos.app.downloadInPdf.printInfo")}
          </div>
        </Prolog>
        <Content
          showReasons={showReasons}
          showAffects={showAffects}
        >
          {trendType === 'localTrends' ?
            <LocalTrends {...data.localTrends} /> :
            <GlobalTrends {...data.globalTrends} />
          }
        </Content>
      </div>
    </>
  );
});

const SelectContainer = styled.div`
  display: flex;
`;

const Content = styled.div<{ showAffects: boolean; showReasons: boolean }>`  
  padding-left: 0.5em;
  color: var(--text-secondary);
  line-height: 1.5;
    
  .ap-affects {
    display: ${props => props.showAffects ? 'block' : 'none'};
  }

  .ap-reasons {
    display: ${props => props.showReasons ? 'block' : 'none'};
  }
  
  h1 {
    font-weight: bold;
    font-size: 1.562rem;
    line-height: 1.875rem;
  }

  h2 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
  
  ul {
    padding: 0;
    margin-left: 0.875rem;
  }

  .gray-list {
    color: #A2A2A2;
  }

  .disclaimer {
    font-family: Times New Roman;
    font-size: 1.2rem;
    font-style: italic;
  }

  .check-boxes-comment {
    width: 100%;
    margin-bottom: 1em;
    font-style: italic;
  }

  .local-trends {
    margin-top: 0.675rem;
  }

  .local-trend {
    margin-bottom: -1.5rem;
    /* border-top: 1px dashed lightgray; */

    @media print {
      margin-bottom: 1rem;
    }
  }

  .actual {
    color: var(--text-secondary);
    font-size: 1em;
  }

  .list-wrap {
    color: var(--text-secondary);
    font-size: 1rem;
  }

  .list-item {
    color: var(--text-secondary);
  }

  .list-item-red {
    color: red;
  }

  .reason {
    color: var(--text-secondary);
    font-size: 1em;
    font-style: italic;
    margin-bottom: 1em;
  }

  .hint {
    font-size: 0.9em;
    /* 0.750rem; */
    font-style: italic;
    margin-bottom: 1.25rem;
  }

  .list-wrap-global {
    color: var(--text-secondary);

    h2 {
      margin-top: 1em;
      color: white;
    }

    h5 {
      color: white;
    }
  }

  .compensation {
    color: green;
    margin-bottom: 3rem;
  }

  .bold-text {
    font-weight: bold;
  }

`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  position: sticky;
  top: 5.7rem;
  background-color: var(--workspace-widget-background);
  // z-index:2;

  & > * {
    margin: .5em 0
  }
`;

const CheckBoxes = styled.div`
  display: flex;

  & > * + * {
    margin-left: 1em
  }
`;

const LoaderContainer = styled.div`
  height: calc(100vh - 10rem);
`;

const StyledToPDFButton = styled(ToPrintButton)`
  margin-left: 0.5rem;
  color: var(--accent-color);
`;

const Validity = styled.div`
  display: flex;
  justify-content: space-between;
  
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  
  span {
    margin-left: 0.4rem;
  }

  .text {
    color: var(--text-secondary);
    padding: 0 1rem;
  }

  .title {
    color: var(--text-secondary);
  }
`

const IconContainer = styled.div`
  width: 3.5rem;
  color: var(--text-secondary);
`