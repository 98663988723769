import React from 'react';
import styled from 'styled-components';

import store from 'src/store';

import { degToString } from 'src/api';
import { IFixedStar, IObject, getSign, ObjectType, signs, CircleMode, isNotTransits, isPrognostics, IMap } from 'src/libs';
import { arabicSigns } from './Horar/Analysis';
import { calcHorarSigns } from 'src/pages/Instruments/Widgets/Horar/horar';
import { getArrangeObjects, fortuneLon, IArrangedObject } from 'src/components/Circle/Objects';
import { objectsIcons, signsIcons } from 'src/icons';
import { ConjunctionIcon } from 'src/assets/icons/system';

import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';
import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';

import { acccesOnlyForRu } from "src/helpers/permissions";
import { IHighlight } from 'src/pages/Instruments/utils';
import { TWidgetCircleMode } from './AspectsTable/ModeSelectorTabs';
import { extractMode } from './AspectsTable/utils';
import { map } from 'lodash';

export interface IFixedStarsViewProps {
  map: IMap;
  title?: string;
  mode: TWidgetCircleMode;
  className?: string;
  onChanged?: (key: string, value: any) => void;
}

interface IStarRow { 
  star: IFixedStar; 
  sign: number; 
  planets: number[]; 
  houses: number[]; 
  el: JSX.Element;
}

export default React.memo(function FixedStarsView(props: IFixedStarsViewProps) {
  
  const { t } = useTranslation();
  const isRu = i18n.language === 'ru';
  
  const orbis = 1;

  const _extractedMode = extractMode(props.mode, '-');
  const isPrognosticsSubMode = isPrognostics(_extractedMode!) && _extractedMode !== props.mode;

  const horarSigns = props.mode === 'horar' ? calcHorarSigns(props.map.houses) : [];
  const orderSize = props.mode === 'horar' ?
    3
    : props.mode === 'soul'
      ? 1
      : 1

  const highlightStarConjuction = (starRow: IStarRow) => (evt: React.MouseEvent) => {
    const addExt = (isAdd: boolean) => isAdd ? '_ext' : '';
    
    const hlData: { items: IHighlight[] } = { 
      items: [
        { type: 'sign', id: starRow.sign, map: _extractedMode! },
        { type: `star${addExt(isPrognosticsSubMode)}`, id: `${starRow.star.en}_${starRow.star.lon}`, map: _extractedMode! },
        ...starRow.planets.map(id => ({ type: `object${addExt(isPrognosticsSubMode)}`, id, map: _extractedMode }) as IHighlight),
        ...starRow.houses.map(id => ({ type: `house${addExt(isPrognosticsSubMode)}`, id, map: _extractedMode }) as IHighlight),
      ]
    }
    props.onChanged?.('highlights', hlData)
    // console.log('highlightStarConjuction -', hlData);
  }

  const clearHighlights = () => {
    props.onChanged?.('highlights', { items: [] });
  }
  
  const rows = React.useMemo(() => {
    const _rows: IStarRow[] = [];
    const personalization = store.settings.personalization.current;

    const _objects = isPrognosticsSubMode ? (props.map.objectsExt || []) : props.map.objects;
    const _houses = isPrognosticsSubMode ? (props.map.housesExt || []) : props.map.houses;

    let arrangedObjects: IArrangedObject[] = getArrangeObjects(
      _objects
        .filter((obj, id) => (id !== ObjectType.Chiron || !isNotTransits(props.mode as CircleMode)) && (props.mode === 'soul' || id !== ObjectType.Selena))
        ?.map((obj, id) => {
          if (props.mode === 'soul' && id === ObjectType.SouthNode) {
            return {
              ...obj,
              lon: fortuneLon(_objects, (_houses || []))
            };
          }
          return obj;
        }) || []
      ,
      (_houses || []),
      +(16 / 4).toFixed(2),
      horarSigns,
      orderSize,
      props.map.strongs || [],
      store.activeAstroProfile?.showHigherPlanets
    );

    for (const star of (props.map.fixedStars || [])) {
      let accuracyAspectHouses: number[] = [];
      _houses?.map((house: any, id: number, currArr: number[]) => {
        const cuspLon = ['horar', 'soul'].includes(props.mode) ? ((props.mode === 'soul' ? 0 : 180) + id * 30) : currArr[id];
        Math.abs(star.lon - currArr[id]) <= orbis && accuracyAspectHouses.push(id);
      });

      let accuracyAspectObjects: number[] = [];

      for (let object of arrangedObjects) {
        if ([ObjectType.Chiron, ObjectType.Lilith, ObjectType.SouthNode, ObjectType.NorthNode].includes(object.id) || (props.mode === 'horar' && object.id === ObjectType.Selena)) continue;
        
        const isFortune = (props.mode === 'soul' && object.id === ObjectType.SouthNode);
        const realLon = isFortune ? fortuneLon((_objects), _houses.length ? _houses : [0]) : _objects[object.id].lon;
        Math.abs(star.lon - realLon) <= orbis && accuracyAspectObjects.push(object.id);
      }

      accuracyAspectHouses = accuracyAspectHouses.sort();
      accuracyAspectObjects = accuracyAspectObjects.sort();

      const sign = getSign(star.lon);

      const SignIcon = signsIcons[sign] as React.ElementType;

      const starName = t(isRu ? star.ru : star.en);
      const bracketIndex = starName.indexOf('(');
      const [starName1, starName2] = bracketIndex === -1
        ? [starName]
        : [starName.slice(0, bracketIndex - 1), starName.slice(bracketIndex)?.replace(/[\(\)]/g, '')];

      if (accuracyAspectHouses.length || accuracyAspectObjects.length) {
        _rows.push({
          star,
          sign,
          planets: accuracyAspectObjects,
          houses: accuracyAspectHouses,
          el: <>
            <TooltipWrapper text={acccesOnlyForRu() ? (t(star.description) === star.description ? '' : t(star.description)) : ''} textAlign='left'>
              <StarName>
                <div className='star-name'>
                  <span>{starName1}</span>
                  {starName2 && <span className="name2">{starName2}</span>}
                </div>
                <ConjunctionIcon />
              </StarName>
            </TooltipWrapper>
            {accuracyAspectHouses.length !== 0 &&
              <House>
                {accuracyAspectHouses.sort().map(house => arabicSigns[house])}
              </House>
            }
            {accuracyAspectObjects.sort().map((object, i) => {
              const Icon = objectsIcons[object] as React.ElementType;
              return <Icon key={`object_icon_${i}`} style={{ fill: 'var(--text-secondary)', flexShrink: '0' }} />;
            })}
            <Sign>
              <span>{degToString(star.lon % 30, {isInternational: false})}</span>
              <SignIcon style={{ fill: `rgb(var(--circle-zodiacs-${signs[sign].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}))` }} />
            </Sign>
          </>,
        });
      }
    }

    

    const withPlanetRows = _rows.filter((row) => row.planets.length)
      .sort((row1:any, row2: any) => {
        return row1.planets[0] === row2.planets[0]
          ? (t(row1.star.ru) > t(row2.star.ru) ? 1 : -1)
          : (row1.planets[0] > row2.planets[0]) ? 1 : -1
      });
    const withCoupsideRows = _rows.filter((row) => {
        const allReadyHas = withPlanetRows.some(({ planets, houses }) => {
          return planets.toString() === row.planets.toString() && houses.toString() === row.houses.toString()
        })
        return row.houses.length && !allReadyHas
      })
      .sort((row1:any, row2: any) => {
        return row1.houses[0] === row2.houses[0]
          ? (t(row1.star.ru) > t(row2.star.ru) ? 1 : -1)
          : (row1.houses[0] > row2.houses[0]) ? 1 : -1
      });

    const firstColNumber = Math.ceil(_rows.length / 2);

    return [
      ...withPlanetRows,
      ...withCoupsideRows
    ]
      .map((row: IStarRow, index) => {
        const num = index + 1;
        const order = num <= firstColNumber
          ? num * 2 - 1
          : (num - firstColNumber) * 2;

        return (
          <Cell 
            key={`fixed_stars_${row.star.en}_${index}`} 
            style={{ order }} 
            className={num <= firstColNumber ? 'first-col' : ''}
            onMouseEnter={highlightStarConjuction(row)}
            onMouseLeave={clearHighlights}
          >
            {row.el}
          </Cell>
        );
      });
  }, [props.map]);

  // if (!rows.length) return null;
  
  return (
    <FixedStarsContainer className={props.className}>
      
      { Boolean(props.title) && 
          <SectionTitle className='section-title'>{props.title}</SectionTitle>
      }

      { Boolean(props.map.fixedStars?.length && !rows.length) &&
          <SectionTitle className='section-title empty'>{'Нет соединения на карте выбранных звезд с планетами и куспидами'}</SectionTitle>
      }

      { 
        // @ts-ignore
        (!Boolean(props.map.fixedStars?.length) || !Boolean(props.map.showFixedStars)) &&
          <SectionTitle className='section-title empty'>{'Выберите или включите звезды в настройках'}</SectionTitle>
      }


      { 
        // @ts-ignore
        Boolean(props.map.fixedStars?.length && rows.length && props.map.showFixedStars) && 
          <SectionBody className='section-body'>
            {rows}
          </SectionBody>}
    
    </FixedStarsContainer>
  );
});

const FixedStarsContainer = styled.div`
  position: relative;
  border-bottom: 1px solid var(--element-neutral);
  
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const SectionTitle = styled.div`
  position: relative;

  & + & {
    margin-top: 1rem;
  }

  &.empty {
    margin-bottom: 0.75rem;
    opacity: 0.67;
  }

`;

const SectionBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  width: calc(50% - 1rem);
  box-sizing: border-box;
  padding: 1rem 0;
  cursor: default;
  
  &.first-col {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid var(--element-neutral);
  }
  
  & > svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StarName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  & .star-name {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .name2 {
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color: var(--text-third);
  }

  & > svg {
    width: 0.8rem;
    height: 0.8rem;
    flex-shrink: 0;
  }
`;

const House = styled.span`
  display: inline-block;
  font-size: 1rem;
  color: var(--text-third);
  flex-shrink: 0;
`;

const Sign = styled.div`
  display: flex;
  color: var(--text-secondary);
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: nowrap;
  }
  
  > svg {
    width: 1rem;
    height: 1rem;
  }
`;