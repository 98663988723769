
const getDataLS = (key: string, dflt: any = null) => {
  try {
    const dataLS = localStorage.getItem(key)
    return dataLS ? JSON.parse(dataLS) : dflt
  } catch(e) {
    console.error(e)
    return dflt
  }
}

export const getPaymentsRestrictionsLS = localStorage.getItem('payments_restrictions')

export const setPaymentsRestrictionsLS = (value: string) => localStorage.setItem('payments_restrictions', value)

export const setUtmLs = (value: string) => localStorage.setItem('utm', value)
export const getUtmLs = () => localStorage.getItem('utm')

interface BannersLs {
  preNewYearSaleBanner?: {
    closed?: boolean
  }
}

export const setBannersLs = (value: BannersLs) => localStorage.setItem('banners', JSON.stringify(value))
export const getBannersLs = (): BannersLs => getDataLS('banners', {})


export interface IGen {
  autoprediction: boolean;
  devplan: boolean;
  rectification: boolean;
}

export interface IGenList {[key: string]: IGen}

export const formGenLS = {

  lsKey: 'gens',

  get(formId?: number | string | undefined, defaultGen: Partial<IGen> = {}): Partial<IGen> | Partial<IGenList> {
    const genAllDataFromLS = JSON.parse(localStorage.getItem(this.lsKey) || '{}');


    if (!formId) {
      return genAllDataFromLS;
    } else {
      const formGenData = genAllDataFromLS[formId];
      const isEquals = JSON.stringify(formGenData) === JSON.stringify(defaultGen);
      return (formGenData && Object.keys(formGenData).length) ? formGenData : defaultGen
    }
    
  },

  set(formId: number | string | undefined, gen: IGen | undefined) {
    const _formId = Number(formId);

    if (!gen) {
      throw new Error(`formGenLS gen is required ${gen}`);
    }
    
    if (!formId || Number.isNaN(_formId)) {
      throw new Error(`formGenLS formId is required ${formId}`);
    } 

    else if (_formId <= 0) {
      throw new Error(`formGenLS not working with item formId ${formId}`);
    }

    else {
      const genData = this.get() as IGenList;
      genData[formId] = gen;
      localStorage.setItem(this.lsKey, JSON.stringify(genData))
    }

    
  }

}
