import React, { useEffect, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import i18n from 'src/i18n/i18n';
import dayjs from 'dayjs';
import {
  InputContainer,
  Checkbox,
  Switcher,
  DateTimeInput,
  PlaceInput,
  GMTSelect,
  Input,
  Button,
  getGMT,
  ISynastryPartnerData,
}  from 'src/libs';

import api, { Gender, IFormData, IPlace, IPlaceEx, makeForm } from 'src/api';
import BirthDayInput from 'src/components/BirthDayInput';
import { useTranslation } from 'src/i18n/useTranslation';
import { getDeviceDetect, isCustomPlace } from 'src/utils';
import { PersonIcon, SuccessColoredIcon } from 'src/assets/icons/system';
import { activeHousesSystem, IFormError, ExtEditFormType } from './utils';
import { Errors } from '.';
import {
  NatalIcon,
  SynastryIcon,
  PrognosticsIcon,
  HorarIcon,
  SoulIcon,
  RelocationIcon
} from 'src/assets/icons/maps';
import { WarningIcon } from 'src/assets/icons/system';

import store from 'src/store';


const { isMobile } = getDeviceDetect();

export const infos = {
  natal: {
    title: "astro.natal",
    icon: <NatalIcon />,
    label: "chronos.app.components.editForm.mainGroup.birth"
  },
  synastry: {
    title: "astro.synastry",
    icon: <SynastryIcon />,
    label: "chronos.app.components.editForm.mainGroup.partnerBirth"
  },
  prognostics: {
    title: "astro.prognostics",
    icon: <PrognosticsIcon />,
    label: "chronos.app.components.editForm.mainGroup.prognosis"
  },
  syn_prognostics: {
    title: "astro.prognostics",
    icon: <PrognosticsIcon />,
    label: "chronos.app.components.editForm.mainGroup.prognosis"
  },
  horar: {
    title: "astro.horar",
    icon: <HorarIcon />,
    label: "chronos.app.components.editForm.mainGroup.question"
  },
  soul: {
    title: "astro.formulaSoul",
    icon: <SoulIcon />,
    label: "chronos.app.components.editForm.mainGroup.birth"
  },
  relocation: {
    title: "chronos.app.settings.relocation", // RU
    icon: <RelocationIcon />,
    label: "astro.relocation.genitive"
  }
};

export default function MainGroup(props: {
  type: ExtEditFormType;
  form?: IFormData;
  place: IPlace | null;
  natal?: ISynastryPartnerData;
  dateTime: string;
  gmt: number;
  fixedGmt?: number | null;
  autoGmt?: boolean;
  cosmogram?: boolean;
  showAspects?: boolean;
  gender?: Gender;
  useNatalPlace?: boolean;
  name?: string;
  selectedFormId?: number | null;
  setPlace(v: IPlace): void;
  disabled?: boolean;
  setPlace(v: IPlace, sybtype?: string): void;
  setDateTime?(v: string): void;
  setInvalidDateTime?(): void;
  setGMT?(v: number, auto?: boolean): void;
  setCosmogram?(v: boolean): void;
  setShowAspects?(v: boolean): void;
  setGender?(v: Gender): void;
  setName?(v: string): void;
  setSelectedFormId?(v: any): void;
  onChangeUseNatalPlace?(val: boolean): void;
  className?: string;
  setDisableForm?(value: boolean): void;
  disabledGmtSelect?: boolean;
  onBlur?(e: any): void;
  formOptions?: any[];
  disabledDateTime?: boolean;
  plainView?: boolean;
	parentContainerRef?: React.RefObject<HTMLFormElement>
}) {
  const { t, U } = useTranslation();
  const info = infos[props.type];
  const [locked, setLocked] = useState(false);
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [invalidDateTime, setInvalidDateTime] = useState(false);
  const [isAddedToMapsList, setIsAddedToMapsList] = useState(false)

  const onGender = (v: string) => props.setGender?.(v as Gender);

  const natalPlaceMessage = React.useMemo((): string | undefined => {
    const { place, natal } = props;
    return natal?.place?.name && (place?.name === natal?.place.name) ? t("chronos.app.mainGroup.filledNatalCity") : undefined;
  }, [props.place, props.natal]);

  const [emptyPlace, setEmptyPlace] = React.useState<boolean>(props.type === 'relocation' && !Boolean(props.place?.name));

  if (props.useNatalPlace && props.cosmogram &&  props.type === 'prognostics') {
    props.onChangeUseNatalPlace?.(false);
  }

  const memorizedSelect = useMemo(() => {
    const options = props.formOptions?.map(option => ({
      ...option,
      action: props.setSelectedFormId,
    })) || [];
    return props.type === 'synastry'
      ? <InputWrapper>
          <Input
            size="medium"
            options={options}
            value={props.selectedFormId ?? 0}
						parentContainerRef={props.parentContainerRef}
          />
        </InputWrapper>
      : null;
  }, [props.formOptions, props.selectedFormId]);

  useEffect(() => {
    setLocked(false);
  }, [props.selectedFormId]);


  const onDateTime = (dt: string, src: string) => {

    if (dt) {
      if (src === 'date') {
        const time = props.dateTime ? props.dateTime.split('T')[1] : '00:00:00';
        const newDate = dayjs(dt).utc().format('YYYY-MM-DD');
        dt = `${newDate}T${time}`
      }

      props.setDateTime?.(dt);
      setInvalidDateTime(false);
    } else {
      props.setInvalidDateTime?.();
      setInvalidDateTime(true);
    }
  };

  const onPlace = (p: IPlace | null) => {
    if (!p) {
      props.type === 'relocation' && setEmptyPlace(true);
      return;
    } else if (p.name != (p as IPlaceEx).originalName || (!p.lat || !p.lon) && props.type === 'relocation') {
      setEmptyPlace(true);
    } else {
      setEmptyPlace(false);
    }
    props.setPlace(p);
  };

  const selectPlaceDetail = async (name: string, id: string, language?: string): Promise<IPlaceEx> => {
    props.setDisableForm?.(true);
    const place = await api.place(name, id, i18n.language);
    props.setDisableForm?.(false);
    return place;
  }

  const checkHandler = () => {
    const errors: IFormError[] = [];

    if(!props.name) {
      errors.push({
        node: 'synastry',
        text: t('chronos.app.components.editForm.noName'),
      });
    }

    if (invalidDateTime) {
      errors.push({
        node: 'synastry',
        text: t('chronos.app.inputBirthDate'),
      });
    }

    if ((props?.place?.name.trim().length === 0) && !props.cosmogram) {
      errors.push({
        node: 'synastry',
        text: t('chronos.app.noLocation'),
      });
    } else if (
      ((props?.place as IPlaceEx)?.id && (props?.place as IPlaceEx)?.originalName != undefined && (props?.place?.name.trim() !== (props?.place as IPlaceEx)?.originalName)) ||
      ( (props?.place as IPlaceEx)?.id && !(props?.place as IPlaceEx)?.originalLat && !(props?.place as IPlaceEx)?.originalLon && (props?.place as IPlaceEx)?.originalName && props?.place?.name) &&
      !props.cosmogram
    ) {
      errors.push({
        node: 'synastry',
        text: t('chronos.app.choosePlaceFromList'),
      });
    }
    if (
      ((props?.place as IPlaceEx)?.id && !props?.place?.lat || !props?.place?.lon) ||
      ((props?.place as IPlaceEx)?.id && !(props?.place as IPlaceEx)?.originalLat && !(props?.place as IPlaceEx)?.originalLon && !(props?.place as IPlaceEx)?.originalName && props?.place?.name)
    ) {
      errors.push({
        node: 'synastry',
        text: t('chronos.app.noCoordinates'),
      });
    }

    setErrors(errors);
    return !!errors.length;
  }

  const saveNewFormHandler = () => {
    window.setTimeout(() => {
      if(checkHandler()) {
        return;
      }

      saveNewForm();
      setIsAddedToMapsList(true)
    }, 200);
  }

  const saveNewForm = async () => {
    try {
      const form = makeForm({
        name: props.name!,
        housesSystem: activeHousesSystem(),
        natal: {
          place: props.place!,
          dt: props.dateTime!,
          gmt: props.gmt ?? 0,
          // @ts-ignore
          gender: props.gender,
        }
      }, store.activeAstroProfile);

      form.natal.dt = props.dateTime!;

      setLocked(true);
      const { id } = await api.addForm(form);
      id && props.setSelectedFormId && props.setSelectedFormId({value: id});
    } catch (e) {
      console.log(`saveNewForm error -`, e);
    }
  }

  if (props.type === 'relocation') return (
    <MainGroupContainer className={props.className}>

      <PlaceGroup>
        <InputContainer
          label={`${t("chronos.app.components.editForm.mainGroup.place")} ${t(info.label)}`}
        >
          <PlaceInput
            autoFocus
            size='small'
            value={props.place?.name || ''}
            place={props.place || ({name: ''} as IPlace)}
            asyncDataFn={api.places.bind(api)}
            asyncDetailFn={selectPlaceDetail}
            onSelect={onPlace}
            userCoordinates={true}
            notice={''}
            matchPlace={
              props.type === 'relocation' ?
                {
                  matchKey: 'name',
                  matchValue: props.natal?.place.name || '',
                  matchMessage: t("chronos.app.mainGroup.cityFromNatal")
                } : undefined}
            t={t}
            lang={i18n.language}
          />
        </InputContainer>
      </PlaceGroup>
      <InputContainer label={`${U("chronos.app.day.nom")} ${t(info.label)}`}>
        <BirthDayInput
          size='small'
          value={!emptyPlace ? props.dateTime : ''}
          gmt={props.gmt}
          place={props.place}
          onChange={val => onDateTime(val, 'date')}
          onInvalid={() => onDateTime('', 'date')}
          autoFocus={false}
          onBlur={props.onBlur}
          disabled={true}
        />
      </InputContainer>
      <Labels>
        <InputContainer label={`${t("chronos.app.components.editForm.mainGroup.time")} ${t(info.label)}`}>
          <DateTimeInput
            size='small'
            type='time'
            value={!emptyPlace ? props.dateTime : ''}
            onChange={(val: string) => onDateTime(val, 'time')}
            hideIcon
            utcMode
            disabled={true}
          />
        </InputContainer>
        <InputContainer label={t("chronos.app.components.editForm.mainGroup.timeZone")}>
          <StyledGMTSelect
            size='small'
            value={!emptyPlace ? (props.fixedGmt ?? props.gmt) : null}
            onChange={props.setGMT!}
            className='gmt-session'
            detectMobile={() => isMobile}
            autoMode={(() => {
              const currentGmt = (getGMT(props.dateTime, (props.place?.lat! || 0), (props.place?.lon! || 0)))
              return (props.fixedGmt! ?? props.gmt) === currentGmt
            })()}
            t={t}
            selection={Boolean(props.fixedGmt! !== null && !isCustomPlace(props.place))}
            disabled={true}
          />
        </InputContainer>
        {(props.fixedGmt !== null && !isCustomPlace(props.place)) && <div className='auto-gmt-message' style={{ opacity: 0.5 }}>
          <WarningIcon/>
          <span>Персональные настройки GMT действуют в рамках выбранного периода, за пределами периода включается автоматическое GMT.</span>
        </div>}
      </Labels>
    </MainGroupContainer>
  )

  return (
    <MainGroupContainer className={props.className}>
      {memorizedSelect}

      {props.type === 'synastry' &&
        <Label>
          <span>{t("base.name")}</span>
          <Input
            value={props.name}
            onChange={props.setName}
            autoFocus={true}
          />
        </Label>
      }

      <InputContainer label={`${t("chronos.app.settings.date")} ${t(info.label)}`}>
        <BirthDayInput
          size='small'
          value={props.dateTime}
          gmt={props.gmt}
          place={props.place}
          onChange={(val) => onDateTime(val, 'date')}
          onInvalid={() => onDateTime('', 'date')}
          autoFocus={props.type !== 'horar' && props.type !== 'synastry'}
          onBlur={props.onBlur}
          hideIcon={props.type === 'horar'}
        />
      </InputContainer>

      <Labels>
        <InputContainer label={`${t("chronos.app.components.editForm.mainGroup.time")} ${t(info.label)}`}>
          <DateTimeInput
            size='small'
            type='time'
            value={props.dateTime}
            onChange={(val: string) => onDateTime(val, 'time')}
            hideIcon
          />
        </InputContainer>

        <InputContainer label={U("chronos.app.components.editForm.mainGroup.timeZone")}>
          <StyledGMTSelect
            size='small'
            value={props.fixedGmt ?? props.gmt}
            onChange={props.setGMT!}
            className='gmt-session'
            detectMobile={() => isMobile}
            autoMode={(() => {
              const currentGmt = (getGMT(props.dateTime, (props.place?.lat! || 0), (props.place?.lon! || 0)))
              return (props.fixedGmt! ?? props.gmt) === currentGmt && !props.plainView
            })()}
            t={t}
            // styled property
            selection={Boolean(props.fixedGmt! !== null && !isCustomPlace(props.place))}
            disabled={props.disabledGmtSelect}
            key={`${props.place?.name}-${props.place?.lat}-${props.place?.lon}`}
          />
        </InputContainer>

        {(props.fixedGmt !== null && !isCustomPlace(props.place)) && <div className='auto-gmt-message'>
          <WarningIcon/>
          <span>Персональные настройки GMT действуют в рамках выбранного периода, за пределами периода включается автоматическое GMT.</span>
        </div>}
      </Labels>

      {
        !(props.useNatalPlace && ['prognostics', 'syn_prognostics'].includes(props.type)) &&
          <PlaceGroup>
            <InputContainer
              label={`${t("chronos.app.components.editForm.mainGroup.place")} ${t(info.label)}`}>
              <PlaceInput
                size='small'
                value={props.place?.name || ''}
                place={props.place || ({name: ''} as IPlace)}
                asyncDataFn={api.places.bind(api)}
                asyncDetailFn={selectPlaceDetail}
                onSelect={onPlace}
                userCoordinates={!props.plainView}
                onDataLoading={props.setDisableForm}
                notice={props.type === 'prognostics' ? natalPlaceMessage : ''}
                matchPlace={
                  props.type === 'prognostics' ?
                    {
                      matchKey: 'name',
                      matchValue: props.natal?.place.name || '',
                      matchMessage: t("chronos.app.mainGroup.cityFromNatal")
                    } : undefined}
                t={t}
                lang={i18n.language}
              />
            </InputContainer>
          </PlaceGroup>
      }


      {
        ['prognostics', 'syn_prognostics'].includes(props.type) && !props.cosmogram && 
        <Checkbox
          label={t("chronos.app.components.editForm.mainGroup.duplicateFromNatal")}
          checked={props.cosmogram ? false : props.useNatalPlace} style={{ marginBottom: '1rem' }}
          onChange={(val: boolean) => { props.onChangeUseNatalPlace?.(val) }}
        />
      }
      {
        props.type === 'horar' && 
        <Cosmogram style={{ marginBottom: '1rem' }}>
          <span>{t("chronos.app.components.editForm.mainGroup.showAspects")}</span>
          <StyledSwitcher checked={props.showAspects ?? false} onChange={props.setShowAspects} />
        </Cosmogram>
      }

      {props.type === 'natal' && props.setCosmogram &&
        <Cosmogram>
          <span>{t("chronos.app.components.editForm.mainGroup.cosmogram")}</span>
          <StyledSwitcher checked={props.cosmogram ?? false} onChange={props.setCosmogram} />
        </Cosmogram>
      }

      {(props.type === 'natal' || props.type === 'synastry') && props.setGender &&
        <InputContainer label="Пол">
          <Input
            size='small'
            value={props.gender}
            options={[
              { value: undefined, label: t("chronos.app.components.editForm.mainGroup.noGender") },
              { value: 'male', label: t("chronos.app.components.editForm.mainGroup.gender.male") },
              { value: 'female', label: t("chronos.app.components.editForm.mainGroup.gender.female") }
            ]}
            onChange={onGender}
          />
        </InputContainer>
      }

      {!locked && !props.selectedFormId && props.type === 'synastry' &&
        <>
          <SavePersonButton color='transparent' onClick={saveNewFormHandler}>
            <PersonIcon />
            <span>{t('chronos.app.components.editForm.mainGroup.addToMapsList')}</span>
          </SavePersonButton>
          <Errors>
            {errors.map(e => <li key={e.text}>{t(e.text)}</li>)}
          </Errors>
        </>
      }

      {locked && isAddedToMapsList &&
        <SuccessSaving>
          <SuccessColoredIcon/>
          <span>{t('chronos.app.components.editForm.mainGroup.mapIsSavedInCRM')}</span>
        </SuccessSaving>
      }
    </MainGroupContainer>
  );
}

const MainGroupContainer = styled.div`
  position: relative;

  & > * + * {
    margin-top: 1.125rem;
  }
`;

const PlaceGroup = styled.div`
  position: relative;

  & > * + * {
    margin-top: 0.688rem;
  }
`;

export const Label = styled.div`
  width: 100%;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  & > span {
    display: block;
    margin-bottom: 0.4rem;
  }

  & ::-webkit-scrollbar {
    width: 2px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--accent-blue);
    border: none;
  }
`;

export const Labels = styled.div<{ count?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.count || 2}, 1fr);
  grid-column-gap: 1.25rem;

  & > .auto-gmt-message {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-block-start: 0.625rem;
    grid-column-start: 1;
    grid-column-end: ${props => props.count ? (props.count + 1) : 3};

    font-size: 0.813rem;
    line-height: 1.188rem;
    color: var(--colors-yellow);

    & > svg {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const Cosmogram = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSwitcher = styled(Switcher)`
  margin-left: auto;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledGMTSelect = styled(GMTSelect) <{ selection: boolean }>`
  ${p => p.selection === true && css`
    & > input {
      color: var(--colors-yellow);
    }
  `}
`;

const SavePersonButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 1px solid var(--button-border);

  & > svg {
    height: 1.125rem;
    margin-right: 0.5rem;
    fill: var(--text-third);
  }
  & > span {
    line-height: 1;
  }
`;

const SuccessSaving = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem 0;
  color: var(--text-primary);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    color: var(--colors-green);
  }
`
