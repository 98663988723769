import React from 'react';
import styled from 'styled-components';

import { toDateTimeString } from 'src/api';

import { ReactMemo } from 'src/hooks';

import { IWidgetData } from './Widget';

import { PrintedHeader, PrintedTitle, PrintedFormData, Prolog } from './utils';
import { ChronosIcon, ProcessorIcon } from 'src/assets/icons/system/index';
import { InfoIcon } from 'src/assets/icons/notifications';

import { Loader, Input, ToPrintButton, IGenInfo } from 'src/libs';

import GenDialog from './GenDialog';
import api from 'src/api';
import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';
import { getDeviceDetect } from 'src/utils';

const { isMobile } = getDeviceDetect();

function listItems(items: string[]) {
  return items.map((item, i) => <li key={`li_${i}`}>{item}</li>);
}

function tenseAspectsHeader(data: any, color: string, className: string) {
  return (
    <div className={`aspect-item ${className}`}>
      <img
        src={data.img}
      />
      <div style={{
        fontWeight: 'bold',
        fontSize: '1rem',
        color
      }}>{data.title}</div>
      <p>{data.text}</p>
    </div>
  );
}

function tenseAspects(items: any[]) {
  return items.map((item, i) =>
    <div className='aspects-block' key={`item_${i}`}>
      {tenseAspectsHeader(item.negative, '#D72222', 'minus')}
      {item.prescription ? tenseAspectsHeader(item.prescription, '#F2994A', 'aspect-item birth') : ''}
      {tenseAspectsHeader(item.compensation, '#3281CA', 'equal')}
      {tenseAspectsHeader(item.positive, '#429F22', 'compensation')}
      <div className="tense-sep" />
    </div>
  );
}

function disclaimer(v: any) {
  return <p className="disclaimer">{v.text} <a href={`mailto:${v.email}`}>{v.email}</a>.</p>;
}

function lifehack(text: string, t: Function) {
  return <p style={{
    paddingLeft: '20px',
    borderLeft: '3px solid currentColor'
  }}><b>{t("chronos.app.compensatoryLifeHack")} </b>{text}</p>;
}

function signal(text: string) {
  return <p className="signal">{text}</p>;
}

function group(group: string[]) {
  return <ul>{group.map((p, i) => <li key={`g_${i}`}>{p}</li>)}</ul>;
}

function result(v: { text: string; marker?: string; }, id: number) {
  const toks = v.text.split('#!');
  return (
    <React.Fragment key={`result_${id}`}>
      <li>
        <div className="block">
          {v.marker && <div className="marker">{v.marker} </div>}
          <div className="text">{toks[0]}</div>
        </div>
      </li>
      {toks.length == 2 && <span className="red-cursive">{toks[1]}</span>}
    </React.Fragment>
  );
}

function birth(data: any, t: Function) {
  return <>
    {data.instruction.label}
    <br />
    {data.instruction.items.map((i: any, id: number) => <p key={`instr_${id}`}>{i}</p>)}

    <ul>
      {data
        .contents
        .map((i: any, id: number) => <li key={`cont_${id}`}><b>{i.label}</b> {i.text}</li>)
      }
    </ul>

    {data.resource.map((i: any, id: number) => <p key={`res_${id}`}>{i}</p>)}

    <div className='print-inseparable'>
      <h2>{data.birth[0].label}</h2>
  
      <p>{data.birth[0].auxLabel}</p>
    </div>

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].aux)}</ul>

    <p>{data.birth[0].baggagesLabel}</p>

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].baggages)}</ul>

    {data.birth[0].next.map((p: string, i: number) => <p key={i.toString()}>{p}</p>)}

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].partners)}</ul>

    {data.birth[0].warnings.map((w: string, i: number) => <p key={`w_${i}`} style={{ color: 'var(--colors-red)', fontWeight: 'bold' }}>!!! {w}</p>)}

    <div className='print-inseparable'>
      <h2>{data.birth[1].label}</h2>
  
      <p>{data.birth[1].description1}</p>
    </div>

    {data.birth[1].moon.length > 0 &&
      <>
        <p>{data.birth[1].description2}</p>

        <ul style={{ color: '#282' }}>{listItems(data.birth[1].moon)}</ul>

        <p>{data.birth[1].comment1}</p>
      </>
    }

    {data.birth[1].moonTenseAspects.length > 0 &&
      <>
        {tenseAspects(data.birth[1].moonTenseAspects)}

        <p>{data.birth[1].comment2}</p>
      </>
    }

    {lifehack(data.birth[1].lifehack, t)}

    <p><b>{data.birth[1].problems.label}</b></p>

    <ul>{data.birth[1].problems.items.map((i: any, id: number) => <li key={`prob1_${id}`}>{i}</li>)}</ul>

    {signal(data.birth[1].signal)}

    <div className='print-inseparable'>
      <h2>{data.birth[2].label}</h2>
  
      <p>{data.birth[2].description1}</p>
    </div>

    {data.birth[2].sun.length > 0 &&
      <>
        <p>{data.birth[2].description2}</p>

        <ul style={{ color: '#282' }}>{listItems(data.birth[2].sun)}</ul>

        <p>{data.birth[2].comment1}</p>
      </>
    }

    {data.birth[2].sunTenseAspects.length > 0 &&
      <>
        {tenseAspects(data.birth[2].sunTenseAspects)}

        <p>{data.birth[2].comment2}</p>
      </>
    }

    {lifehack(data.birth[2].lifehack, t)}

    <p><b>{data.birth[2].problems.label}</b></p>

    <ul>{data.birth[2].problems.items.map((i: any, id: number) => <li key={`prob2_${id}`}>{i}</li>)}</ul>

    {signal(data.birth[2].signal)}

    <div className='print-inseparable'>
      <h2>{data.birth[3].label}</h2>
  
      <p>{data.birth[3].description1}</p>
    </div>

    <p>{data.birth[3].description2}</p>

    <p>{data.birth[3].description3}</p>

    <ul className="gray-list">{listItems(data.birth[3].habits)}</ul>

    <p>{data.birth[3].saturnProblemsLabel}</p>

    <ul className="gray-list">{listItems(data.birth[3].saturnProblems)}</ul>

    <p>{data.birth[3].jupiterLabel}</p>

    <ul className="gray-list">{listItems(data.birth[3].jupiter)}</ul>

    <p><b>{data.birth[3].problems.label}</b></p>

    <ul>{data.birth[3].problems.items.map((i: any, id: number) => <li key={`prob3_${id}`}>{i}</li>)}</ul>

    {signal(data.birth[3].signal)}

    <div className='print-inseparable'>
      <h2>{data.birth[4].label}</h2>
  
      <p>{data.birth[4].description1}</p>
    </div>

    <p>{data.birth[4].description2}</p>

    <p>{data.birth[4].tasksLabel}</p>

    <ul className="gray-list">{listItems(data.birth[4].tasks)}</ul>

    <p>{data.birth[4].retributionLabel}</p>

    <ul className="gray-list">{listItems(data.birth[4].retribution)}</ul>

    {disclaimer(data.disclaimer)}
  </>;
}

function energy(data: any) {
  return <>
    <div className='print-inseparable'>
      <h2>{data.question}</h2>
  
      <p>{data.potential}</p>
    </div>

    <p>{data.firstSource}</p>

    <ul className="gray-list">
      {listItems(data.energySigns)}
    </ul>

    <p>{data.secondSource}</p>

    <p>{data.warning}</p>

    <ul className="gray-list">
      {listItems(data.marsHouses)}
    </ul>

    {data.marsTenseAspects.length > 0 &&
      <>
        <p>{data.marsTenseAspectsLabel}</p>
        {tenseAspects(data.marsTenseAspects)}
      </>
    }

    {disclaimer(data.disclaimer)}
  </>;
}

function health(data: any, t: Function) {
  return <>
    <p style={{ color: 'green' }}>{data.warning}</p>

    <p>{data.warning2}</p>

    {data.group1.length > 0 && <>
      <p>{data.group1Label}</p>

      <div style={{
        color: 'red',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {data.group1Aux.map((a: string, i: number) => <div key={`aux_${i}`}>{a}</div>)}
      </div>

      {group(data.group1)}
    </>}

    {data.group2.length > 0 && <>
      <p>{data.group2Label}</p>
      {group(data.group2)}
    </>}

    {data.group1.length > 0 && data.group2.length > 0 && <p>{data.warning3}</p>}
    {data.group1.length === 0 && data.group2.length ===  0 ? 
      <p>{t("chronos.app.devPlan.noRiskMessage")}</p>:
      <div>
        {data.probabilities.map((p: string, i: number) => <p
        key={`prob_${i}`}
        style={{
          fontWeight: 'bold',
          color: '#D00000'
        }}
        >{p}</p>)}
      </div>
    }
  </>;
}

function proforientation(data: any) {
  return <>
    <i>{data.warning}</i>

    <p>{data.talentsMapLabel}</p>

    <ul>
      {
        data.talentsMap.map((t: any, i: number) => {
          const text = `+${t.v} ${t.k}`;
          return <li key={`talent_${i}`}>
            <div className="block">
              <div className="text">{text}</div>
            </div>
          </li>
        })
      }
    </ul>

    <p>{data.priorityDirections}</p>

    <ul>{data.niches.map((n: any, i: number) => <li key={i.toString()}>{n.k}</li>)}</ul>
  </>;
}

function finance(data: any) {
  return <>
    {data.recommendation}

    <br />
    <br />

    <ol style={{ paddingLeft: '2em' }}>
      {data.instruction.map((t: string, i: number) => <li key={`instr_${i}`}>{t}</li>)}
    </ol>

    <br />
    <hr />
    <br />

    {data.label1}

    <br />
    <br />

    {data.commonMoneyMakersLabel}

    <ol style={{ paddingLeft: '3em' }}>
      <li style={{ marginTop: '1em' }}>
        <span style={{ fontWeight: 'bold', color: '#373' }}>{data.yinYang.who}&nbsp;</span>
        {data.yinYang.lines.map((i: string, id: number) => <React.Fragment key={`yy_${id}`}>{i}<br /></React.Fragment>)}
        {data.yinYang.warning && <span className="red-cursive">{data.yinYang.warning}</span>}
      </li>
      {data.commonMoneyMakers.map(result)}
    </ol>

    <br />

    {data.specificMoneyMakersLabel}

    <br />

    <ul>
      {data.specificMoneyMakers.map(result)}
    </ul>

    <br />
    <hr />
    <br />

    {data.label2}

    {data.realization.map((r: any, id: number) =>
      <React.Fragment key={`real_${id}`}>
        <div style={{ marginTop: '2em' }}>{r.label}</div>
        <span style={{ color: r.color }}>{r.text}</span>
      </React.Fragment>
    )}

    <br />
    <br />

    <hr />

    <br />

    {data.label3}

    <ul>
      {(data.top7 as any[]).map((t, i) =>
        <li key={`top7_${i}`}>
          <div className="block">
            {t.marker && <div className="marker">{t.marker}</div>}
            <div className="text">{t.text}</div>
          </div>
        </li>
      )}
    </ul>
  </>;
}

export default ReactMemo(function DevPlan(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [page, setPage] = React.useState<number>(1);
  const [info, setInfo] = React.useState<IGenInfo | null>(null);
  const [data, setData] = React.useState<{
    birth: string;
    energy: string;
    proforientation: string;
    health: string;
    finance: string;
  } | null>(null);

  const { t } = useTranslation();

  // const { isLimitedAccess } = store.settings.user;

  const selectedFormations = [
    { value: 1, label: t("chronos.app.devPlan.birth") },
    { value: 2, label: t("chronos.app.devPlan.energy") },
    { value: 3, label: t("chronos.app.devPlan.careerGuidance") },
    { value: 4, label: t("chronos.app.devPlan.health") },
    { value: 5, label: t("chronos.app.devPlan.finance") }
  ]

  const onBuild = async (confirmed = true) => {
    const resp = await api.devplan(confirmed, props.data.form, i18n.language);

    props.onChanged('devplan', resp.done);

    if (resp.done && resp.data) {
      setInfo(null);
      setData(resp.data);
    } else {
      // Force update
      setInfo({
        ...resp,
        timestamp: Date.now()
      });
      setData(null);
    }
  };

  React.useEffect(() => {
    onBuild(false);
  }, []);

  const checkDevPlan = async () => {
    const resp = await api.devplan(false, props.data.form, i18n.language);
    if (resp.done) return
    setInfo({
      ...resp,
      timestamp: Date.now()
    });
    setData(null);
  };

  React.useEffect(() => {
    checkDevPlan()
  }, [props.data.form.id, props.data.form.natal.dt]);

  if (info) {
    return (
      <GenDialog
        title={"chronos.app.devPlan"}
        info={info}
        onBuild={onBuild}
      />
    );
  }

  if (!data) { return <LoaderContainer><Loader /></LoaderContainer> }

  return (
    <>
      <div id="print-header">
        <PrintedHeader>
          <div>
            <ChronosIcon />
            <ProcessorIcon />
          </div>
          <div className="label">{t("chronos.app.devPlan")}</div>
        </PrintedHeader>
        <PrintedTitle>
          {selectedFormations.find(({value}) => value === page)?.label}
        </PrintedTitle>
        <PrintedFormData>
          <span>{props.data.form.name}</span>,&nbsp;
          <span>{toDateTimeString(props.data.form.natal.dt)}</span>,&nbsp;
          <span>{props.data.form.natal.place.name}</span>
        </PrintedFormData>
      </div>
      <SelectContainer>
        <Input
          options={selectedFormations}
          value={page}
          onChange={setPage}
          detectMobile={() => isMobile}
        />

        <StyledToPDFButton
          size='small'
          color='transparent'
          elements={['#print-header', '.map-print-wrapper', '#print-content']}
          excludes={['.close-button', 'button']}
          inseparable={['.print-inseparable']}
        >
          {t("chronos.app.downloadInPdf")}
        </StyledToPDFButton>
        
      </SelectContainer>
      <div id="print-content">
        <Prolog>
          <InfoIcon/>
          <div className="text">
            {t("chronos.app.downloadInPdf.printInfo")}
          </div>
        </Prolog>
        <Content>
          {page === 1 && birth(data.birth, t)}
          {page === 2 && energy(data.energy)}
          {page === 3 && proforientation(data.proforientation)}
          {page === 4 && health(data.health, t)}
          {page === 5 && finance(data.finance)}
        </Content>
      </div>
    </>
  );
});


const SelectContainer = styled.div`
  display: flex;
  min-width: 200px;
  margin-bottom: 1.5rem;

  position: sticky;
  top: 5.7rem;
  background-color: var(--workspace-widget-background);
  // z-index:2;

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  max-width: 30em;
  color: var(--text-secondary);
  line-height: 1.5;
  
  h2 {
    margin-bottom: 0.313em;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    color: var(--text-primary);

    :not(:first-child) {
      margin-top: 0.875em;
    }
  }

  h5 {
    margin-bottom: 1.5em;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--text-third);
  }

  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 2em;
    color: var(--text-secondary);

    li:not(:last-child) {
      margin-bottom: 1em;
    }

    li {
      b {
        color: var(--text-primary);
      }

      .block {
        display: flex;
      }

      .marker {
        margin-right: 0.5rem;
      }
    }

  }

  p {
    margin-bottom: 1.215em;
    color: var(--text-secondary);
  }

  .list-item {
    color: var(--text-secondary);
  }

  .list-item_bold {
    font-weight: bold;
  }

  .list-item_black {
    color: var(--text-primary);
  }
  
  .disclaimer {
    font-style: italic;
  }

  .red-cursive {
    font-style: italic;
    color: #D00000;
  }

  .signal {
    font-weight: bold;
    color: red;
  }

  .tense-sep {
    margin: 1.75em 0;
    border-top: 1px solid var(--element);
  }

  .aspect-item {
    display: block !important;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.375rem;
    border: 1px solid;

    @media print {
      border-width: 1pt !important;
    }
  }

  .aspect-item.minus {
    background-color: rgba(239, 83, 80, 0.1);
    border-color: rgba(239, 83, 80, 0.5);
  }
  .aspect-item.birth {
    background: rgba(242, 153, 74, 0.1);
    border: 1px solid rgba(242, 153, 74, 0.5);
    @media print {
      border-width: 1pt;
    }
  }
  .aspect-item.equal {
    background-color: rgba(64, 147, 244, 0.1);
    border-color: rgba(64, 147, 244, 0.5);
  }

  .aspect-item.compensation {
    background-color: rgba(115, 178, 48, 0.1);
    border-color: rgba(115, 178, 48, 0.5);
  }

  .aspect-item h1 {
    font-size: 1.125rem;
    line-height: 1;
    margin: 0.625rem 0;
  }

  .aspect-item svg,
  .aspect-item img {
    width: 1.5rem;
    height: 1.5rem;
    
    @media print {
      margin-bottom: 1rem;
    }
  }

  .aspect-item p {
    margin: 1rem 0;
  }


  @media print {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 1rem;
    font-size: 1.125rem;

    /* .print-inseparable {
      page-break-inside: avoid;
      page-break-after: avoid;
    } */
  }
`;

const LoaderContainer = styled.div`
  height: calc(100vh - 10rem);
`;

const StyledToPDFButton = styled(ToPrintButton)`
  margin-left: 0.5rem;
  color: var(--accent-color);
`;
