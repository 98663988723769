import React from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import store from 'src/store';

import { switcherHook } from 'src/hooks';

import { Input, Button, CircleMode, IFormData } from 'src/libs';
import CloseButton from 'src/ui/CloseButton';

import { SettingsIcon } from 'src/assets/icons/system';

import { Label } from './MainGroup';

import { Buttons, Container, Errors, Header, Main } from './index';
import { IFormError } from './utils';
import { useTranslation } from 'src/i18n/useTranslation';
import { Section } from './Section';
import { HousesSystemSelect } from './HousesSystemSelect';

export default function Common(props: {
  form: IFormData;
  onClose(): void;
  onSubmit(form: IFormData): void;
  modes: CircleMode[];
}) {
  const [errors, setErrors] = React.useState<IFormError[]>([]);

  const [name, setName] = React.useState(props.form.name);
  const [common, setCommon] = React.useState(props.form.housesSystem.common);
  const [northern, setNorthern] = React.useState(props.form.housesSystem.northern);
  const [horar,  setHorar ] = React.useState(props.form.housesSystem.horar);
  const { common: sections } = store.settings.profile.ui.instruments.cardSettingsForms;
  const isHorar = props.modes.includes('horar');

  const basicIsOpened = switcherHook(sections.basic);
  const hSysIsOpened = switcherHook(sections.common);
  const nothernHSysIsOpened = switcherHook(sections.northern);

  const { astro: astroStore } = store;

  const sectionsChanged = (key: string, value: boolean) => {
    store.settings.profile.updateUi(ui => {
      (ui.instruments.cardSettingsForms.common as any)[key] = value;
    });
  };

  const onSubmit = (evt: any) => {
    evt && evt.preventDefault();

    const errors: IFormError[] = [];

    if (props.form.id != -1 && name.trim().length === 0) {
      errors.push({
        node: 'natal',
        text: "chronos.app.components.editForm.natal.text"
      });
    }

    setErrors(errors);

    if (errors.length) { return }

    const form = cloneDeep(props.form);

    props.onSubmit({
      ...form,
      name,
      housesSystem: {
        ...form.housesSystem,
        common,
        northern,
        horar
      }
    });

    store.dashboard.loadForms()

    props.onClose();
  };
  const { t } = useTranslation();
  return (
    <StyledContainer onSubmit={onSubmit}>
      <Header>
        <SettingsIcon />
        <span>{t("chronos.app.components.editForm.cardSettings")}</span>
        <CloseButton onClick={props.onClose} />
      </Header>

      <Main>
        {!props.form.isTemporary &&
          <Section
            title={t("chronos.app.components.editForm.baseData")}
            isOpened={basicIsOpened.value}
            switch={() => sectionsChanged('basic', basicIsOpened.switch())}
          >
            <Label>
              <span>{t("chronos.app.components.editForm.name")}</span>
              <Input
                value={name}
                onChange={setName}
                placeholder={t("chronos.app.titleInput")}
              />
            </Label>
          </Section>
        }

        <Section
          title={t("chronos.app.components.editForm.houseSystem")}
          isOpened={hSysIsOpened.value}
          switch={() => sectionsChanged('common', hSysIsOpened.switch())}
        >
          {isHorar ? (
          <HousesSystemSelect
            value={horar}
            setValue={setHorar}
         />
        ) : (
          <HousesSystemSelect
            value={common}
            setValue={setCommon}
          />
        )}
        </Section>

        <Section
          title={t("chronos.app.components.editForm.houseSystem.forNorthernLatitudes", { latitude: store.activeAstroProfile?.northernLatitude || 0 })}
          isOpened={nothernHSysIsOpened.value}
          switch={() => sectionsChanged('northern', nothernHSysIsOpened.switch())}
        >
          <HousesSystemSelect
            value={northern}
            setValue={setNorthern}
          />
        </Section>

        <Errors>
          {errors.map(e => <li key={e.text}>{e.text}</li>)}
        </Errors>

      </Main>

      <Buttons>
        <Button type="submit">{t("base.save")}</Button>
        <Button onClick={props.onClose} color="transparent">{t("base.cancel")}</Button>
      </Buttons>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  bottom: unset;
  top: calc(100% + 1em);
`;
