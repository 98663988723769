import store from 'src/store';
import { show, ChronosApi, IShortFormData, S3Urls, IAuth } from 'src/libs';

export * from 'src/libs';

export const s3urls = new S3Urls();

class Api extends ChronosApi {
  constructor() {
    super(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_AUTH as string, undefined, `https://${process.env.REACT_APP_NODE_ENV === 'development' ? 'dev-' : ''}places.chronos.mg` as string);
  }

  showError(text: string) {
    show({
      type: 'error',
      closable: false,
      timeout: 0,
      text
    });
  }

  async form(id: number) {
    return super.form(id, store.activeAstroProfile?.housesSystem); // checkToken, store.settings.user.id !== -1);
  }

  onUpdateShortForms(forms: IShortFormData[]) {
    // FIXME:
    // @ts-ignore
    return store.dashboard.forms = forms;
  }

  onUpdateAuth(auth: IAuth) {
    store.settings.user.auth = auth;
  }
}

export default new Api();
