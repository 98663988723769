import dayjs from 'dayjs';
import { EditFormType, ISynastryPartnerData, IBaseData, IPlaceEx, toLocaleISOString, makeForm, defaultHousesSystem, IFormData } from "src/libs";

import {
  NatalIcon,
  SynastryIcon,
  PrognosticsIcon,
  HorarIcon,
  RelocationIcon
} from 'src/assets/icons/maps';

import store from 'src/store';

export const MAX_PARTNERS_AMOUNT = 5;

export type ExtEditFormType = EditFormType | 'syn_prognostics';
export type TNodes = 'natal' | 'synastry' | 'prognostics' | 'horar' | 'relocation';

export interface IFormProps {
  formId: number;
  onClose?(): void;
  onSubmit(form: IFormData): void;
}

export interface IFormError {
  node: ExtEditFormType | null;
  text: string;
}

export interface IPartnerDataWithAdditionalParams extends ISynastryPartnerData {
  id?: number;
  invalidDateTime?: boolean;
    opened: boolean;
}

export const nodesInfo: {
  [key: TNodes | string]: {
    text: string;
    icon: any;
  };
} = {
  natal: {
    text: "astro.natal",
    icon: <NatalIcon />
  },
  synastry: {
    text: "astro.synastry",
    icon: <SynastryIcon />
  },
  prognostics: {
    text: "astro.prognostics",
    icon: <PrognosticsIcon />
  },
  horar: {
    text: "astro.horar",
    icon: <HorarIcon />
  },
  relocation: {
    text: "astro.relocation",
    icon: <RelocationIcon />
  }
};

export function activeHousesSystem() {
  return store.activeAstroProfile?.housesSystem || defaultHousesSystem;
}

export function defaultBaseData(): IBaseData {
  return {
    dt: toLocaleISOString(dayjs().toISOString()),
    gmt: dayjs().utcOffset() / 60, // GMT
    autoGmt: true,
    place: { name: '' } as IPlaceEx
  };
}

export function _defaultForm(form = makeForm({ name: '', natal: defaultBaseData(), housesSystem: activeHousesSystem() } )) {
  delete form.synastry;
  delete form.prognostics;
  delete form.horar;
  delete form.rectification;
  delete form.relocation;
  
  form.partners = [];
  return form;
}